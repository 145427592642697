import { message } from 'antd';
import axios from 'axios';
import { FilterObject, StakeholderModel } from 'digicust_types';
import { GENERIC_URL } from './constants';

export class StakeholderRepository {
  public static getList = async (
    customerId?: string,
    projectId?: string,
    options?: {
      continuationToken?: string;
      filter?: FilterObject[];
      sort?: { field: string; order: 'ASC' | 'DESC' }[];
      properties?: string[];
      search?: string[];
      page?: number;
      pageLimit?: number | string;
    },
  ): Promise<any> => {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/${customerId}/${projectId}/stakeholders?options=${encodeURIComponent(
          JSON.stringify(options),
        )}`,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  public static get = async (
    customerId?: string,
    projectId?: string,
    id?: string,
  ): Promise<StakeholderModel | null> => {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/${customerId}/${projectId}/stakeholders/${id}`,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return null;
    }
  };

  public static delete = async (
    customerId?: string,
    projectId?: string,
    id?: string,
  ): Promise<any> => {
    try {
      const response = await axios.delete(
        `${GENERIC_URL}/api/${customerId}/${projectId}/stakeholders`,
        { data: { idArray: [id] } },
      );
      message.success('Stakeholder successfully deleted.');
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return null;
    }
  };

  public static save = async (
    customerId?: string,
    projectId?: string,
    caseData?: StakeholderModel,
  ): Promise<StakeholderModel | null> => {
    try {
      if (caseData?.id && customerId && projectId) {
        const stakeholder = {
          stakeholderData: caseData,
        };
        const response = await axios.put(
          `${GENERIC_URL}/api/${customerId}/${projectId}/stakeholders/${caseData.id}`,
          stakeholder,
        );
        // message.success('Stakeholder successfully updated');
        return response.data.result;
      }

      if (customerId && projectId) {
        const stakeholder = {
          stakeholderData: caseData,
        };
        const response = await axios.post(
          `${GENERIC_URL}/api/${customerId}/${projectId}/stakeholders`,
          stakeholder,
        );
        message.success('Stakeholder successfully created');
        return response.data.result;
      }
      return {};
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return null;
    }
  };

  public static bulkUpdateStakeholderData = async (
    data: any,
    customerId?: string,
    projectId?: string,
  ) => {
    try {
      const response = await axios.put(
        `${GENERIC_URL}/api/${customerId}/${projectId}/stakeholders/bulkEdit`,
        data,
      );
      return response;
    } catch (error) {
      return error;
    }
  };

  public static getCountryCode = async (lng: string) => {
    try {
      const response = await axios.get(`/locales/${lng}/countries.json`);
      return response;
    } catch (error) {
      return error;
    }
  };

  public static getPackagesList = async (lng: string) => {
    try {
      const response = await axios.get(`/locales/${lng}/packageList.json`);
      return response;
    } catch (error) {
      return error;
    }
  };
}
