import Editor from '@monaco-editor/react';
import { Space } from 'antd';
import jsonata from 'jsonata';
import { useEffect, useState } from 'react';
import registerJsonata from '../../utils/helpers/JSONata/jsonataMode';

const JSONataInput = ({
  inputValue,
  onChange,
  sampleInput,
}: {
  inputValue?: string;
  onChange?: (e: string) => void;
  sampleInput?: any;
}) => {
  const [value, setValue] = useState<any>(inputValue);

  const [jsonataOutputElement, setJsonataOutputElement] = useState<any>();

  useEffect(() => {
    const setTimeOut = window.setTimeout(() => {
      onChange && onChange(value);
    }, 2000);

    return () => {
      clearTimeout(setTimeOut);
    };
  }, [value]);

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  useEffect(() => {
    calculateJSONataOutput();
  }, [value]);

  const calculateJSONataOutput = async () => {
    try {
      const expression = jsonata(value);
      const result = await expression.evaluate(sampleInput);
      setJsonataOutputElement(JSON.stringify(result));
    } catch (error: any) {
      return setJsonataOutputElement(
        <span style={{ color: 'red' }}>{error.message}</span>,
      );
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Editor
        className="editorInput"
        height="300px"
        width="100%"
        defaultLanguage="jsonata"
        theme="jsonataTheme"
        value={value}
        beforeMount={registerJsonata}
        options={{
          minimap: {
            enabled: false,
          },
          lineNumbers: 'off',
        }}
        onChange={(newValue) => {
          setValue(newValue);
        }}
      />
      <Space direction="vertical" style={{ marginTop: '10px' }}>
        <div>
          <span>JSONata Expression Evaluation: </span>
          <span style={{ color: 'green' }}>{jsonataOutputElement}</span>
        </div>
      </Space>
    </div>
  );
};

export default JSONataInput;
