import { TariffNumberSuggestion } from 'digicust_types';

/**
 * Sorts the classification suggestions based on confidence and reject values
 * @param suggestions
 * @returns
 */
export const sortTariffSuggestions = (
  suggestions: TariffNumberSuggestion[],
): TariffNumberSuggestion[] => {
  if (!suggestions.length) return [];

  const sortedSuggestions = suggestions
    .sort((a, b) => b.confidence - a.confidence)
    .reduce<[TariffNumberSuggestion[], TariffNumberSuggestion[]]>(
      ([nonRejected, rejected], suggestion) => {
        if (suggestion.rejected) {
          rejected.push(suggestion);
        } else {
          nonRejected.push(suggestion);
        }
        return [nonRejected, rejected];
      },
      [[], []],
    );

  return sortedSuggestions.flat();
};
