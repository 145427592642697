import { CustomsOffice, Port, TariffSystem } from 'digicust_types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDataContext } from '../Pages/AppDataProvider';
import { CodesRepository } from './repositories/codes.repository';

type CountryCodes = {
  alpha2Code: string;
  country: string;
};

export const useCountryCodes = (): CountryCodes[] => {
  const [countryCodes, setCountryCodes] = useState<CountryCodes[]>([]);
  useEffect(() => {
    CodesRepository.getCodes('Countries').then((codes) =>
      setCountryCodes(
        codes?.map((code: any) => ({
          alpha2Code: code.alpha2code,
          country: code.name,
        })) || [],
      ),
    );
  }, []);
  return countryCodes;
};

export const usePorts = () => {
  const [ports, setPorts] = useState<Port[]>([]);
  useEffect(() => {
    CodesRepository.getCodes('Ports').then((codes) => setPorts(codes || []));
  }, []);
  return ports;
};

export type TaricTariffNumberTreeNode = {
  code: string;
  description: string;
  children: TaricTariffNumberTreeNode[];
};

export const useTaricTariffNumbersTree = (): TaricTariffNumberTreeNode[] => {
  const { projectDetails } = useAppDataContext();
  const [tariffNumbers, setTariffNumbers] = useState<
    TaricTariffNumberTreeNode[]
  >([]);
  useEffect(() => {
    CodesRepository.getTaricTariffNumberTree(
      projectDetails?.tariffNumberTreeSystem || TariffSystem.DE,
    ).then((codes) => setTariffNumbers(codes || []));
  }, []);
  return tariffNumbers;
};

export type TariffNumber = { code: string; description: string };

export type AdditionalTariffNumber = {
  code: string;
  language: string;
  description: string;
};

export const useTariffNumbers = (): TariffNumber[] => {
  const { projectDetails } = useAppDataContext();
  const [tariffNumbers, setTariffNumbers] = useState<TariffNumber[]>([]);
  useEffect(() => {
    CodesRepository.getTariffNumbers(
      projectDetails?.tariffNumberTreeSystem || TariffSystem.DE,
    ).then((codes) => setTariffNumbers(codes || []));
  }, []);
  return tariffNumbers;
};

export const useAdditionalTariffNumbers = (): AdditionalTariffNumber[] => {
  const [additionalTariffNumbers, setAdditionalTariffNumbers] = useState<
    AdditionalTariffNumber[]
  >([]);
  useEffect(() => {
    CodesRepository.getAdditionalTariffNumberCodes().then((codes) =>
      setAdditionalTariffNumbers(codes || []),
    );
  }, []);
  return additionalTariffNumbers;
};

export const useCustomsOffices = (): CustomsOffice[] => {
  const [customsOffices, setCustomsOffices] = useState<CustomsOffice[]>([]);
  useEffect(() => {
    CodesRepository.getCodes('CustomsOffices').then((codes) =>
      setCustomsOffices(
        codes?.map((code: any) => ({
          code: {
            value: code?.referenceNumber,
          },
          input: code?.referenceNumber,
          value: code?.referenceNumber,
          name: { value: code?.name },
          address: {
            ...(code?.address || {}),
            formattedAddress: `${code?.address?.streetName || ''} ${
              code?.address?.postalCode || ''
            } ${code?.address?.locality || ''}`,
          },
          phone: { value: code?.phone },
          email: { value: code?.email },
        })) || [],
      ),
    );
  }, []);
  return customsOffices;
};

export const useCurrencies = () => {
  const [currencies, setCurrencies] = useState<
    { code: string; name: string }[]
  >([]);
  useEffect(() => {
    CodesRepository.getCodes('Currencies').then((codes) =>
      setCurrencies(
        codes?.map((code: any) => ({
          code: code.code,
          name: code.name,
        })) || [],
      ),
    );
  }, []);
  return currencies;
};

export const useUnitsOfMeasurement = () => {
  const [unitsOfMeasurement, setUnitsOfMeasurement] = useState<
    { code: string; name: string }[]
  >([]);
  useEffect(() => {
    CodesRepository.getCodes('UnitsOfMeasurement').then((codes) =>
      setUnitsOfMeasurement(
        codes?.map((code: any) => ({
          code: code.code?.toString(),
          name: code.name?.toString(),
        })) || [],
      ),
    );
  }, []);
  return unitsOfMeasurement;
};

export const useProcedureCodes = () => {
  const [procedureCodes, setProcedureCodes] = useState<
    { code: string; name: string; additional: string }[]
  >([]);
  useEffect(() => {
    CodesRepository.getCodes('ProcedureCodes').then((codes) =>
      setProcedureCodes(
        codes?.map((code: any) => ({
          additional: code.additional?.toString(),
          code: code.code?.toString(),
          name: code.name?.toString(),
        })) || [],
      ),
    );
  }, []);
  return procedureCodes;
};

export const usePreferenceCodes = () => {
  const [preferenceCodes, setPreferenceCodes] = useState<
    { code: string; name: string }[]
  >([]);
  useEffect(() => {
    CodesRepository.getCodes('PreferenceCodes').then((codes) =>
      setPreferenceCodes(
        codes?.map((code: any) => ({
          code: code.code?.toString(),
          name: code.name?.toString(),
        })) || [],
      ),
    );
  }, []);
  return preferenceCodes;
};

export const useDocumentTypes = () => {
  const [documentTypeCodes, setDocumentTypeCodes] = useState<
    { code: string; name: string; segmentDE: string }[]
  >([]);
  useEffect(() => {
    CodesRepository.getDocumentTypeCodes().then((codes) => {
      setDocumentTypeCodes(
        codes?.map((code: any) => ({
          code: `${code?.certificateType?.toString()}${code?.code?.toString()}`,
          name: code.certificateDesc?.toString(),
          segmentDE: code.segmentDE?.toString(),
        })) || [],
      );
    });
  }, []);
  return documentTypeCodes;
};

export const useWeightUnits = () => {
  const { t } = useTranslation();
  const weightUnits = [
    {
      name: t('Kilogram'),
      code: 'kg',
    },
    {
      name: t('Pound'),
      code: 'lb',
    },
    {
      name: t('Gram'),
      code: 'g',
    },
    {
      name: t('Ounce'),
      code: 'ounce',
    },
    {
      name: t('Milligram'),
      code: 'mg',
    },
    {
      name: t('Dekagram'),
      code: 'dag',
    },
    {
      name: t('Ton'),
      code: 't',
    },
  ];

  return weightUnits;
};
