import { message } from 'antd';
import axios from 'axios';
import {
  ExecutionStrategy,
  FilterCondition,
  RuleCatalogItem,
} from 'digicust_types';
import { GENERIC_URL } from './constants';

export class ExecutionStrategyRepository {
  public static getList = async (
    customerId?: string,
    projectId?: string,
    options?: {
      continuationToken?: string;
      filter?: {
        field: string;
        condition: FilterCondition;
        value: string | undefined | boolean | Date | number;
      }[];
      sort?: { field: string; order: 'ASC' | 'DESC' }[];
      properties?: string[];
      search?: string[];
      page?: number;
      pageLimit?: number | string;
    },
  ): Promise<ExecutionStrategy[]> => {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/${customerId}/${projectId}/execution-strategies?options=${encodeURIComponent(
          JSON.stringify(options),
        )}`,
      );
      return response.data?.result;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return [];
    }
  };

  public static getRuleCatalog = async (
    customerId: string,
    projectId: string,
  ): Promise<RuleCatalogItem[]> => {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/${customerId}/${projectId}/execution-strategies/rule-catalog`,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Could not load rule catalog.');
      return [];
    }
  };

  public static get = async (
    customerId?: string,
    projectId?: string,
    id?: string,
  ): Promise<any> => {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/${customerId}/${projectId}/execution-strategies/${id}`,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return null;
    }
  };

  public static delete = async (
    customerId?: string,
    projectId?: string,
    id?: string,
  ): Promise<any> => {
    try {
      const response = await axios.delete(
        `${GENERIC_URL}/api/${customerId}/${projectId}/execution-strategies/${id}`,
      );
      message.success('Execution strategy successfully deleted.');
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return null;
    }
  };

  public static save = async (
    customerId?: string,
    projectId?: string,
    executionStrategy?: ExecutionStrategy,
  ): Promise<any> => {
    try {
      if (executionStrategy?.id && customerId && projectId) {
        const response = await axios.patch(
          `${GENERIC_URL}/api/${customerId}/${projectId}/execution-strategies/${executionStrategy.id}`,
          executionStrategy,
        );
        message.success('Execution strategy successfully updated');
        return response.data;
      }
      if (customerId && projectId) {
        const response = await axios.post(
          `${GENERIC_URL}/api/${customerId}/${projectId}/execution-strategies`,
          executionStrategy,
        );
        message.success('Execution strategy successfully created');
        return response.data;
      } else {
        return {} as any;
      }
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return null;
    }
  };
}
