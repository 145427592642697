import { ExecutionStrategy } from 'digicust_types';
import CardWrapper from '../../Common/CardWrapper';
import { useTranslation } from 'react-i18next';

export const DataValidationCard = ({
  value,
  onValueChange,
}: {
  value: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();
  return (
    <CardWrapper
      alternative
      title="Data Validation"
      checked={value?.dataValidation?.active}
      onChange={(e) =>
        onValueChange({
          ...value,
          dataValidation: {
            ...(value.dataValidation || {}),
            active: e,
          },
        })
      }
      description={t(
        'Digicust automatically validates your cases and if something is not coherent, sends you warning messages.',
      )}
    />
  );
};
