import { KBarProvider } from 'kbar';
// MSAL imports
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import Portal from './Pages/Portal';
import RequestInterceptor from './utils/RequestInterceptor';
import './i18n/i18n';

import React, { useContext } from 'react';
import FullPageLoader from './common/FullPageLoader';
import { ConfigProvider } from 'antd';
import { ThemeContext } from './utils/ThemeContext';
import { theme } from 'antd';

function App({ pca }: { pca: IPublicClientApplication }) {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);

  return (
    <ConfigProvider
      locale={{
        locale: 'en',
      }}
      theme={
        isDarkMode
          ? {
              algorithm: theme.darkAlgorithm,
            }
          : {
              algorithm: theme.defaultAlgorithm,
              token: { colorBgLayout: 'white' },
            }
      }
    >
      <React.Suspense fallback={<FullPageLoader />}>
        <MsalProvider instance={pca}>
          <RequestInterceptor>
            <KBarProvider>
              <Portal />
            </KBarProvider>
          </RequestInterceptor>
        </MsalProvider>
      </React.Suspense>
    </ConfigProvider>
  );
}

export default App;
