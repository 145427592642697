import { Button, DatePicker, Input, InputRef, Modal, Space } from 'antd';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type ReferenceModalData = {
  reference: string;
  issueDate: string;
  NC_49000: string;
  NC_43000: string;
  NC_44000: string;
  I0903: string;
  /** main document type code */
};

export default function ReferenceModal({
  isModalOpen,
  closeModal,
  onConfirm,
  value,
  onValueChange,
}: {
  isModalOpen: boolean;
  closeModal: () => void;
  onConfirm: (obj: ReferenceModalData) => void;
  value: string;
  onValueChange: (val: string) => void;
}) {
  const { t } = useTranslation();
  const [showAdvancedFields, setAdvancedFields] = useState(false);

  const [reference, setReference] = useState<string>('');
  const [issueDate, setIssueDate] = useState<string | null>();
  // AdvanceFields
  const [NC_49000, setNC_49000] = useState<string>('');
  const [NC_43000, setNC_43000] = useState<string>('');
  const [NC_44000, setNC_44000] = useState<string>('');
  const [I0903, setI0903] = useState<string>('');

  const clearState = () => {
    setAdvancedFields(false);
    setReference('');
    setIssueDate('');
    setNC_49000('');
    setNC_43000('');
    setNC_44000('');
    setI0903('');
    onValueChange('');
  };

  const handleCancel = () => {
    clearState();
    closeModal();
  };

  const handleOk = () => {
    onConfirm({
      reference,
      issueDate: issueDate || '',
      NC_49000,
      NC_43000,
      NC_44000,
      I0903,
    });
    clearState();
    closeModal();
  };

  const inputRef = useRef<InputRef>(null);

  return (
    <Modal
      title={t('Add New Document')}
      open={isModalOpen}
      afterOpenChange={() => inputRef.current?.focus()}
      onCancel={handleCancel}
      onOk={handleOk}
      okText={t('Add')}
      cancelText={t('Cancel')}
      width={350}
      destroyOnClose
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Input
          ref={inputRef}
          autoFocus
          type="text"
          value={reference}
          onChange={(e) => setReference(e.target.value)}
          placeholder={t('Reference - Optional')}
        />

        <DatePicker
          style={{ width: '100%' }}
          placeholder={t('Select Date - Optional')}
          format="DD-MM-YYYY"
          value={issueDate ? dayjs(issueDate) : null}
          onChange={(date) => setIssueDate(date?.toISOString() || '')}
        />
        <Input
          type="text"
          value={value}
          onChange={(e) => onValueChange(e.target.value)}
          placeholder={`Value - ${t('Optional')}`}
        />
        {showAdvancedFields ? (
          <>
            <Input
              type="text"
              value={NC_49000}
              onChange={(e) => setNC_49000(e.target.value)}
              placeholder={`NC_49000 - ${t('Optional')}`}
            />
            <Input
              type="text"
              value={NC_44000}
              onChange={(e) => setNC_44000(e.target.value)}
              placeholder={`NC_44000 - ${t('Optional')}`}
            />
            <Input
              type="text"
              value={NC_43000}
              onChange={(e) => setNC_43000(e.target.value)}
              placeholder={`NC_43000 - ${t('Optional')}`}
            />
            <Input
              type="text"
              value={I0903}
              onChange={(e) => setI0903(e.target.value)}
              placeholder={`I0903 - ${t('Optional')}`}
            />
          </>
        ) : (
          <Button type="link" onClick={() => setAdvancedFields(true)}>
            {t('Show Advanced Fields')}
          </Button>
        )}
      </Space>
    </Modal>
  );
}
