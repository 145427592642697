import { ProColumns } from '@ant-design/pro-components';
import { Button, Space, Typography } from 'antd'; // Importing Button from antd
import { LineItemModel } from 'digicust_types';
import { useTranslation } from 'react-i18next';

export const useCompactViewColumns = () => {
  const { t } = useTranslation();

  const compactViewColumns: ProColumns<LineItemModel, 'text'>[] = [
    {
      title: '#',
      key: 'index',
      fixed: 'left',
      width: 45,
      align: 'center',
      render: (_, item, index) => (
        <Button size="small" type="text" title={t('Copy Data')}>
          {index + 1}
        </Button>
      ),
    },
    {
      title: t('Description'),
      key: 'description',
      fixed: 'left',
      width: '270px',
      render: (_, item) => (
        <Typography.Text title={item?.description?.value} ellipsis>
          {item?.description?.value}
        </Typography.Text>
      ),
    },
    {
      title: t('Tariff Number'),
      key: 'customsTariffNumber',
      width: '120px',
      render: (_, item) => (
        <Typography.Text title={item?.customsTariffNumber?.value} ellipsis>
          {item?.customsTariffNumber?.value}
        </Typography.Text>
      ),
    },
    {
      title: t('Preference'),
      key: 'preference',
      width: '110px',
      render: (_, item) => (
        <Typography.Text ellipsis>
          {item?.preferenceState?.DE_A1200}
        </Typography.Text>
      ),
    },
    {
      title: t('Documents Type Codes'),
      key: 'documents',
      width: '200px',
      render: (_, item) => (
        <Typography.Text ellipsis>
          {item?.documents
            ?.map((doc) => `${doc?.documentCode?.DE_I0200}`)
            ?.join(', ')}
        </Typography.Text>
      ),
    },
    {
      title: t('Procedure'),
      key: 'procedure',
      width: '110px',
      render: (_, item) => (
        <Typography.Text ellipsis>
          {item?.customsProcedure?.procedureCodeCombination}
        </Typography.Text>
      ),
    },
    {
      title: t('Net weight'),
      key: 'totalNetWeight',
      width: '100px',
      render: (_, item) => (
        <Typography.Text ellipsis>
          {item?.totalNetWeight?.value?.toFixed(2)} {item?.totalNetWeight?.unit}
        </Typography.Text>
      ),
    },
    {
      title: t('Gross Weight'),
      key: 'totalGrossWeight',
      width: '120px',
      render: (_, item) => (
        <Typography.Text>
          {item?.totalNetWeight?.value?.toFixed(2)}{' '}
          {item?.totalGrossWeight?.unit}
        </Typography.Text>
      ),
    },
    {
      title: t('Quantity'),
      key: 'quantity',
      width: '110px',
      render: (_, item) => (
        <Typography.Text ellipsis>
          {item?.quantity?.value} {item?.quantity?.uom?.DE_I0700}
        </Typography.Text>
      ),
    },
    {
      title: t('Origin'),
      key: 'countryOfOrigin',
      width: '80px',
      render: (_, item) => (
        <Typography.Text ellipsis>
          {item?.countryOfOrigin?.alpha2Code}
        </Typography.Text>
      ),
    },
    {
      title: t('Value'),
      key: 'totalValue',
      width: '110px',
      render: (_, item) => (
        <Typography.Text ellipsis>
          {item?.totalValue?.value?.toFixed(2)} {item?.totalValue?.unit}
        </Typography.Text>
      ),
    },
    {
      title: t('Article Number'),
      key: 'code',
      width: '200px',
      render: (_, item) => (
        <Typography.Text>
          {[
            item?.code?.value,
            item?.materialNumber?.value,
            item?.buyerArticleNumber?.value,
            item?.reference?.value,
            item?.buyerOrderNumber?.value,
          ]
            .filter((i) => !!i)
            .join(', ')}
        </Typography.Text>
      ),
    },
    {
      title: t('Packages'),
      key: 'packages',
      width: '80px',
      render: (_, item) => (
        <Typography.Text ellipsis>
          {item?.packages
            ?.map((doc) => `${doc?.amount?.value} ${doc?.type?.code}`)
            ?.join(', ')}
          {item?.includedWithPosition?.value
            ? `${t('Included with position')} ${item?.includedWithPosition
                ?.value}`
            : ''}
        </Typography.Text>
      ),
    },
    {
      title: t('Special Unit'),
      key: 'specialUnit',
      width: '110px',
      render: (_, item) => (
        <Typography.Text ellipsis>
          {item?.itemSpecialUnitMeasure?.value}{' '}
          {item?.itemSpecialUnit?.DE_I0700}
        </Typography.Text>
      ),
    },
    {
      title: t('Customs Value'),
      key: 'customsValue',
      width: '120px',
      render: (_, item) => (
        <Typography.Text ellipsis>
          {item?.customsValue?.value} {item?.customsValue?.unit}
        </Typography.Text>
      ),
    },
    {
      title: t('Customs Duty'),
      key: 'customsDuty',
      width: '120px',
      render: (_, item) => (
        <Typography.Text ellipsis>
          {item?.customsDuty?.value?.toFixed(2)} {item?.customsDuty?.unit}
        </Typography.Text>
      ),
    },
    {
      title: t('Statistical'),
      key: 'statisticalValue',
      width: '110px',
      render: (_, item) => (
        <Typography.Text ellipsis>
          {item?.statisticalValue?.value} {item?.statisticalValue?.unit}
        </Typography.Text>
      ),
    },
    {
      title: t('Tax'),
      key: 'importSalesTax',
      width: '110px',
      render: (_, item) => (
        <Typography.Text ellipsis>
          {item?.importSalesTax?.value} {item?.importSalesTax?.unit}
        </Typography.Text>
      ),
    },
    {
      title: t('Freight Cost Dist'),
      key: 'freightCostDistribution',
      width: '170px',
      render: (_, item) => (
        <Space>
          {item?.customsValueAdditionDeduction?.map((c) =>
            [c.amount, c.currency, c.type].filter((i) => !!i).join(', '),
          )}
        </Space>
      ),
    },
  ];

  return { compactViewColumns };
};
