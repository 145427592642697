import { Select, InputNumber, Space, Typography, Input } from 'antd';
import { Meta } from 'digicust_types';
import { BaseSelectRef } from 'rc-select';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DebouncedInput from '../../Inputs/Common/DebouncedInput';
const XKSupplementaryUnit = ({
  value,
  onChange = () => null,
  isCopied,
}: {
  value?: {
    Supplementary_unit_code?: Meta<string>;
    Supplementary_unit_name?: Meta<string>;
    Supplementary_unit_amount?: Meta<number>;
  };
  onChange?: (value?: {
    Supplementary_unit_code?: Meta<string>;
    Supplementary_unit_name?: Meta<string>;
    Supplementary_unit_amount?: Meta<number>;
  }) => void;
  onCopy?: () => void;
  isCopied?: boolean;
}) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState<number>();

  useEffect(() => {
    setInputValue(value?.Supplementary_unit_amount?.value);
  }, [value?.Supplementary_unit_amount?.value]);

  return (
    <Space.Compact
      style={{
        maxWidth: '100%',
        width: '100%',
        outline: isCopied ? '2px blue solid' : '',
        borderRadius: 6,
      }}
    >
      <InputNumber
        variant="filled"
        style={{ width: '50%' }}
        placeholder={t('Amount')}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e || undefined);
        }}
        onBlur={(e) => {
          onChange({
            ...(value || {}),
            Supplementary_unit_amount: {
              input: inputValue?.toString(),
              value: inputValue,
            },
          });
        }}
      />
      <DebouncedInput
        style={{ width: '50%' }}
        placeholder={t('Unit')}
        value={value?.Supplementary_unit_code?.value}
        onChange={(e) => {
          const inputValue = e;
          onChange({
            ...(value || {}),
            Supplementary_unit_code: {
              input: inputValue,
              value: inputValue,
            },
            Supplementary_unit_name: {
              input: inputValue,
              value: inputValue,
            },
          });
        }}
      />
    </Space.Compact>
  );
};

export default XKSupplementaryUnit;
