import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Space } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { WiStars } from 'react-icons/wi';

export const Tips = ({
  value,
  onValueChange,
}: {
  value: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        height: '100%',
        maxWidth: '300px',
        overflowY: 'auto',
      }}
    >
      <Space
        direction="vertical"
        style={{
          width: '100%',
          overflow: 'hidden',
          paddingLeft: '10px',
        }}
      >
        <Card
          style={{ width: '100%' }}
          size="small"
          title={
            <>
              <WiStars
                color="rgb(22, 119, 255)"
                size={26}
                style={{ marginLeft: -10, marginTop: -20, marginBottom: -9 }}
              />
              {t('Idea')}
            </>
          }
          extra={<Button type="text" size="small" icon={<CloseOutlined />} />}
        >
          <p>{t('Set default procedure')}</p>
        </Card>
      </Space>
    </div>
  );
};
