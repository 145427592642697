import { LineItemModel } from 'digicust_types';
import { Key, useState } from 'react';

export default function useLineItemHook() {
  const [dense, setDense] = useState<boolean>(false);
  const [aggregate, setAggregate] = useState<boolean>(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const [selectedItems, setSelectedItems] = useState<LineItemModel[]>([]);

  return {
    dense,
    setDense,
    aggregate,
    setAggregate,
    selectedRowKeys,
    setSelectedRowKeys,
    allSelected,
    setAllSelected,
    selectedItems,
    setSelectedItems,
  };
}
