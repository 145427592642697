import {
  ProColumns,
  ProTable,
  ProTableProps,
} from '@ant-design/pro-components';
import { Flex } from 'antd';
import { ReactNode } from 'react';

export type DGColumns<T> = ({
  getValue?: (data: T) => string | number | boolean | undefined | null;
} & ProColumns<T, 'text'>)[];

export type DGTableProps<T> = Omit<
  ProTableProps<T, object, 'text'>,
  'toolbar' | 'toolBarRender'
> & {
  columns: DGColumns<T>;
  leftToolbar?: ReactNode;
  rightToolbar?: ReactNode;
};

export default function DGTable<T extends Record<string, any>>(
  props: DGTableProps<T>,
) {
  const getColumns = (columns: DGColumns<T>): ProColumns<T, 'text'>[] => {
    return columns.map((col) => {
      if (col.getValue) {
        return {
          ...col,
          onFilter: (value, record) => {
            const recordValue = col.getValue!(record);
            return typeof recordValue === 'string'
              ? recordValue.indexOf(value as string) === 0
              : recordValue === value;
          },
          filters:
            props.dataSource
              ?.map((item) => {
                const value = col.getValue!(item);
                return value !== undefined && value !== null
                  ? { text: String(value), value }
                  : null;
              })
              .filter(
                (
                  item,
                  index,
                  self,
                ): item is { text: string; value: string | number | boolean } =>
                  item !== null &&
                  self.findIndex((t) => t?.value === item.value) === index,
              ) || [],
          filterSearch: true,
          sorter: (a, b) => {
            const aValue = col.getValue!(a);
            const bValue = col.getValue!(b);
            if (typeof aValue === 'string' && typeof bValue === 'string') {
              return aValue.localeCompare(bValue);
            } else {
              return Number(aValue) - Number(bValue);
            }
          },
        };
      } else {
        return col;
      }
    });
  };

  return (
    <div style={props.style}>
      <Flex justify="space-between" style={{ padding: '4px 0' }}>
        <Flex align="center" gap={2}>
          <span style={{ fontWeight: 600 }}>{props.headerTitle}</span>
          {props.leftToolbar}
        </Flex>
        <div>{props.rightToolbar}</div>
      </Flex>

      <ProTable
        {...props}
        style={{}}
        size="small"
        search={false}
        options={false}
        toolBarRender={false}
        columns={getColumns(props.columns)}
      />
    </div>
  );
}
