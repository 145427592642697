import { createSlice } from '@reduxjs/toolkit';

export interface StatisticsReduxState {
  data: any;
  CreateCompany?: boolean;
}

export const initialStatisticsState: StatisticsReduxState = {
  data: null,
  CreateCompany: false,
};

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState: initialStatisticsState,
  reducers: {
    setStatisticsData: (state, action) => {
      state.data = action.payload;
    },
    setCreateCompany: (state, action) => {
      state.CreateCompany = action.payload;
    },
  },
});

export const { setStatisticsData, setCreateCompany } = statisticsSlice.actions;
export default statisticsSlice.reducer;
