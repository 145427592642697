import { Divider, Form, Input, Select } from 'antd';
import { CustomerModel } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import CountrySelect from '../../../Components/Inputs/CountrySelect';
import CardWrapper from '../Common/CardWrapper';

const CustomerComponent = ({
  value,
  onChange,
}: {
  value: CustomerModel;
  onChange: (value: CustomerModel) => void;
}) => {
  const { t } = useTranslation();

  return (
    <CardWrapper alternative={true}>
      <Form>
        <Form.Item label={t('Name')}>
          <Input
            placeholder={t('Company Name')}
            value={value?.name}
            onChange={(e) => onChange({ ...value, name: e.target.value })}
          />
        </Form.Item>
        <Form.Item label={t('Country')}>
          <CountrySelect
            value={{ alpha2Code: value?.country }}
            onChange={(e) =>
              onChange({
                ...value,
                country: e?.alpha2Code || '',
              })
            }
          />
        </Form.Item>
        <Form.Item label={t('Address')}>
          <Input
            placeholder={t('Address')}
            value={value?.companyAddress}
            onChange={(e) =>
              onChange({ ...value, companyAddress: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label={t('City')}>
          <Input
            placeholder={t('City')}
            value={value?.city}
            onChange={(e) => onChange({ ...value, city: e.target.value })}
          />
        </Form.Item>
        <Form.Item label={t('ZIP Code')}>
          <Input
            placeholder={t('ZIP Code')}
            value={value?.zipCode}
            onChange={(e) => onChange({ ...value, zipCode: e.target.value })}
          />
        </Form.Item>
        <Form.Item label={t('Commercial Register Entry')}>
          <Input
            placeholder={t('Commercial Register Entry')}
            value={value?.commercialRegistryEntry}
            onChange={(e) =>
              onChange({ ...value, commercialRegistryEntry: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item label={t('Tax Number')}>
          <Input
            placeholder={t('Tax Number')}
            value={value?.taxNumber}
            onChange={(e) => onChange({ ...value, taxNumber: e.target.value })}
          />
        </Form.Item>
        <Form.Item label={t('VAT Number')}>
          <Input
            placeholder={t('VAT Number')}
            value={value?.vatNumber}
            onChange={(e) => onChange({ ...value, vatNumber: e.target.value })}
          />
        </Form.Item>
        <Form.Item label={t('EORI Number')}>
          <Input
            placeholder={t('EORI Number')}
            value={value?.eoriNumber}
            onChange={(e) => onChange({ ...value, eoriNumber: e.target.value })}
          />
        </Form.Item>

        <Divider orientation="left" orientationMargin="0">
          {t('Help us understand your needs')}
        </Divider>
        <Form.Item label={t('Flags')}>
          <Select
            showSearch
            placeholder={t('Flags')}
            optionFilterProp="children"
            value={value?.flags}
            onChange={(e) => onChange({ ...value, flags: e })}
            mode="multiple"
          >
            {/* <Select.Option value='billable'>Billable</Select.Option> */}
            <Select.Option value="using">{t('Using')}</Select.Option>
            <Select.Option value="archived">{t('Archived')}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label={t('Type of Company')}>
          <Select
            showSearch
            placeholder={t('Type of Company')}
            optionFilterProp="children"
            value={value?.typeOfCompany}
            onChange={(e) => onChange({ ...value, typeOfCompany: e })}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Select.Option value="forwarder">{t('Forwarder')}</Select.Option>
            <Select.Option value="customsBroker">
              {t('Customs Broker')}
            </Select.Option>
            <Select.Option value="manufacturingCompany">
              {t('Manufacturing Company')}
            </Select.Option>
            <Select.Option value="tradingCompany">
              {t('Trading Company')}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label={t('Industry')}>
          <Select
            showSearch
            placeholder={t('Industry')}
            optionFilterProp="children"
            value={value?.typeOfIndustry}
            onChange={(e) => onChange({ ...value, typeOfIndustry: e })}
            filterOption={(input, option) =>
              option!.label.toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                value: 'agricultureForestryFishing',
                label: 'Agriculture, Forestry & Fishing',
              },
              { value: 'miningQuarrying', label: 'Mining and Quarrying' },
              { value: 'manufacturing', label: 'Manufacturing' },
              {
                value: 'electricityGasSteamAirConditioningSupply',
                label: 'Electricity, Gas, Steam and Air Conditioning Supply',
              },
              {
                value: 'waterSupplySewerageWasteManagementRemediation',
                label:
                  'Water Supply; Swerage; Waste Management and Remediation ',
              },
              { value: 'construction', label: 'Construction' },
              {
                value: 'wholesaleRetailTradeRepairMotorVehiclesMotorcycles',
                label:
                  'Wholesale and Retail Trade; Repair of Motor Vehicles and Motorcycles',
              },
              {
                value: 'transportationStorage',
                label: 'Transportation and Storage',
              },
              {
                value: 'accomodationAndFoodService',
                label: 'Accomodation and Food Service ',
              },
              {
                value: 'informationAndCommunication',
                label: 'Information and Communication',
              },
              {
                value: 'financialInsurance',
                label: 'Financial and Insurance ',
              },
              { value: 'realEstate', label: 'Real Estate ' },
              {
                value: 'professionalScientificTechnical',
                label: 'Professional, Scientific and Technical ',
              },
              {
                value: 'administrativeSupportService',
                label: 'Administrative and Support Service ',
              },
              {
                value: 'publicAdministrationDefenceCompulsorySocialSecurity',
                label:
                  'Public Administration and Defence; Compulsory Social Security',
              },
              { value: 'education', label: 'Education' },
              {
                value: 'humanHealthSocialWork',
                label: 'Human Health and Social Work ',
              },
              {
                value: 'artsEntertainmentRecreation',
                label: 'Arts, Entertainment and Recreation',
              },
              { value: 'otherServices', label: 'Other Services' },
              {
                value: 'extraterritorialOrganisationsBodies',
                label: 'Extraterritorial Organisations and Bodies',
              },
            ]}
          />
        </Form.Item>
        <Form.Item label={t('Company Size')}>
          <Select
            showSearch
            placeholder={t('Company Size')}
            optionFilterProp="children"
            value={value?.companySize}
            onChange={(e) => onChange({ ...value, companySize: e })}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Select.Option value="1-10">1-10 Employees</Select.Option>
            <Select.Option value="11-50">11-50 Employees</Select.Option>
            <Select.Option value="51-250">51-250 Employees</Select.Option>
            <Select.Option value="251-1000">251-1000 Employees</Select.Option>
            <Select.Option value="> 1000">{'> 1000 Employees'}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label={t('Number of Customers')}>
          <Select
            showSearch
            placeholder={t('Number of Customers')}
            optionFilterProp="children"
            value={value?.numberOfCustomers}
            onChange={(e) => onChange({ ...value, numberOfCustomers: e })}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Select.Option value="1-10">1-10 Customers</Select.Option>
            <Select.Option value="11-100">11-100 Customers</Select.Option>
            <Select.Option value="101-250">101-250 Customers</Select.Option>
            <Select.Option value="251-500">251-500 Customers</Select.Option>
            <Select.Option value="501-1000">501-1000 Customers</Select.Option>
            <Select.Option value="1001-10000">
              1001-10000 Customers
            </Select.Option>
            <Select.Option value="> 10000">{'> 10000 Customers'}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label={t('Use of Software')}>
          <Select
            showSearch
            placeholder={t('Use of Software')}
            optionFilterProp="children"
            value={value?.useOfSoftware}
            onChange={(e) => onChange({ ...value, useOfSoftware: e })}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Select.Option value="company">
              {t('For own company')}
            </Select.Option>
            <Select.Option value="service">
              {t('For customs clearance services')}
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </CardWrapper>
  );
};

export default CustomerComponent;
