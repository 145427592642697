import { useEffect } from 'react';

const useScrollHandler = (handler: any) => {
  useEffect(() => {
    const tableScrollContainer = document.querySelector('.ant-table-body');
    if (tableScrollContainer) {
      tableScrollContainer.addEventListener('scroll', handler);
    }
    return () => {
      if (tableScrollContainer) {
        tableScrollContainer.removeEventListener('scroll', handler);
      }
    };
  }, [handler]);
};

export default useScrollHandler;
