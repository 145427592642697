import { ProTable } from '@ant-design/pro-components';
import { Space } from 'antd';
import { LineItemModel } from 'digicust_types';
import { useTranslation } from 'react-i18next';

const { Summary } = ProTable;
const { Row, Cell } = Summary;

export default function LineItemsSummary({
  lineItems,
}: {
  lineItems: LineItemModel[];
}) {
  const { t } = useTranslation();

  const calculateSumByUnit = (
    valueData: LineItemModel[],
    key: string,
  ): string[] => {
    const sumByUnit: Record<string, number> = {};
    valueData?.forEach((item: Record<string, any>) => {
      const unit = item?.[key]?.unit;
      const numericValue = parseFloat(item?.[key]?.value);
      if (!isNaN(numericValue)) {
        if (sumByUnit[unit]) {
          sumByUnit[unit] += numericValue;
        } else {
          sumByUnit[unit] = numericValue;
        }
      }
    });
    return Object.keys(sumByUnit).map(
      (unit) => `${sumByUnit[unit].toFixed(2)} ${unit}`,
    );
  };

  const style: React.CSSProperties = {
    marginLeft: 6,
  };

  return (
    <Summary fixed>
      <Row>
        <Cell index={0} />
        <Cell align="center" index={1}>
          {`${lineItems?.length || 0}`}
        </Cell>
        <Cell index={2}>{t('Items')}</Cell>
        <Cell index={3} />
        <Cell index={4} />
        <Cell index={5} />
        <Cell index={6}>
          <Space size={0} style={style} direction="vertical">
            {calculateSumByUnit(lineItems, 'totalNetWeight').map(
              (value) => `${value}`,
            )}
          </Space>
        </Cell>
        <Cell index={7}>
          <Space size={0} style={style} direction="vertical">
            {calculateSumByUnit(lineItems, 'totalGrossWeight').map(
              (value) => `${value}`,
            )}
          </Space>
        </Cell>

        <Cell index={8}>
          <Space size={0} style={style} direction="vertical">
            {lineItems
              ?.reduce((prev: any[], curr) => {
                if (curr?.quantity?.uom?.value) {
                  const existingUnitIndex = prev.findIndex(
                    (item) => item?.uom?.value === curr?.quantity?.uom?.value,
                  );
                  if (existingUnitIndex >= 0) {
                    prev[existingUnitIndex].amount +=
                      curr?.quantity?.amount || 0;
                  } else {
                    prev.push({ ...(curr.quantity || {}) });
                  }
                }
                return prev;
              }, [])
              .map((item) => `${item?.amount} ${item?.uom?.value}`)
              .join(', ')}
          </Space>
        </Cell>
        <Cell index={9} />
        <Cell index={10}>
          <Space size={0} style={style} direction="vertical">
            {calculateSumByUnit(lineItems, 'totalValue').map(
              (value) => `${value}`,
            )}
          </Space>
        </Cell>

        <Cell index={11} />
        <Cell index={12}>
          <Space size={0} style={style} direction="vertical">
            {lineItems
              ?.reduce((prev: any[], curr) => {
                if (curr?.packages?.[0]?.type?.code) {
                  const existingUnitIndex = prev.findIndex(
                    (item) => item?.type === curr?.packages?.[0]?.type?.code,
                  );
                  if (existingUnitIndex >= 0) {
                    prev[existingUnitIndex].amount +=
                      curr?.packages?.[0]?.amount?.value || 0;
                  } else {
                    prev.push({
                      type: curr?.packages?.[0]?.type?.code,
                      amount: curr?.packages?.[0]?.amount?.value,
                    });
                  }
                }
                return prev;
              }, [])
              .map((item) => `${item?.amount} ${item?.type}`)
              .join(', ')}
          </Space>
        </Cell>
        <Cell index={14}>
          <Space size={0} style={style} direction="vertical">
            {lineItems
              ?.reduce((prev: any[], curr) => {
                if (curr?.itemSpecialUnit?.value) {
                  const existingUnitIndex = prev.findIndex(
                    (item) => item?.uom?.value === curr?.itemSpecialUnit?.value,
                  );
                  if (existingUnitIndex >= 0) {
                    prev[existingUnitIndex].value +=
                      curr?.itemSpecialUnitMeasure?.value || 0;
                  } else {
                    prev.push({
                      ...(curr.itemSpecialUnitMeasure || {}),
                      uom: { value: curr?.itemSpecialUnit?.value },
                    });
                  }
                }
                return prev;
              }, [])
              .map(
                (item: any) =>
                  `${isNaN(item?.value) ? 0.0 : item?.value?.toFixed(2)} ${item
                    ?.uom?.value}`,
              )
              .join(', ')}
          </Space>
        </Cell>
      </Row>
    </Summary>
  );
}
