import { CopyOutlined } from '@ant-design/icons';
import { CheckCard } from '@ant-design/pro-components';
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import confirm from 'antd/es/modal/confirm';
import { ExecutionStrategy } from 'digicust_types';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { DCTooltip } from '../../../../common';
import { DocumentTypeSelect } from '../../../../Components/Inputs/DocumentTypeSelect';
import JSONataInput from '../../../../Components/Inputs/JSONataInput';
import CardWrapper, { cardBorderCss } from '../../Common/CardWrapper';

export const UploadCard = ({
  value,
  onValueChange,
}: {
  value: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  return (
    <CardWrapper
      title={t('Upload')}
      description={t('Specify how cases are being uploaded to Digicust')}
      alternative
    >
      <Alert
        message={
          value?.whitelistedImportEmails?.find((email) =>
            email.includes('@with.digicust.com'),
          ) ? (
            <Typography.Text>
              {t('Send emails to')}{' '}
              {value?.whitelistedImportEmails
                ?.filter((email) => email?.includes('@with.digicust.com'))
                ?.map((email) => (
                  <Typography.Text strong copyable>
                    {email}
                  </Typography.Text>
                ))}{' '}
              {t(
                'to upload customs cases to this execution strategy. Simply attach PDF documents.',
              )}{' '}
              <Typography.Link
                onClick={() =>
                  onValueChange({ ...value, whitelistedImportEmails: [] })
                }
              >
                {t('Remove email')}
              </Typography.Link>
            </Typography.Text>
          ) : (
            t(
              'Save execution strategy to get a unique email address for this execution strategy to which you can send customs cases to.',
            )
          )
        }
        type="info"
      />

      <div style={{ marginTop: '10px' }}>
        <div className="hoverable" style={{ display: 'inline-block' }}>
          {t('Default document type')}
          <DCTooltip
            text={t(
              'Assume uploaded documents to be of this document type. If unsure, select Invoice and Digicust will classify automatically.',
            )}
          />
          <DocumentTypeSelect
            size="small"
            value={value?.defaultClassification}
            onChange={(e) =>
              onValueChange({ ...value, defaultClassification: e })
            }
          />
        </div>

        <div className="hoverable" style={{ display: 'inline-block' }}>
          {t('Default Procedure')}
          <DCTooltip
            text={t(
              'Assume uploads in this strategy to be of this customs procedure.',
            )}
          />
          <Select
            popupMatchSelectWidth={false}
            size="small"
            showSearch
            placeholder={t('Procedure')}
            optionFilterProp="children"
            value={value.defaultProcedureMode}
            onChange={(e) =>
              onValueChange({ ...value, defaultProcedureMode: e })
            }
          >
            <Select.Option value="export">{t('Export')}</Select.Option>
            <Select.Option value="import">{t('Import')}</Select.Option>
            <Select.Option value="intrastat">{t('Intrastat')}</Select.Option>
            <Select.Option value="transit">{t('Transit')}</Select.Option>
            <Select.Option value={undefined}>{t('None')}</Select.Option>
          </Select>
        </div>
      </div>

      <Checkbox
        className="hoverable"
        checked={
          value?.handleXLSXFileUploads === true ||
          value?.XLSXFileUploads?.active ||
          false
        }
        onChange={(e) =>
          onValueChange({
            ...value,
            handleXLSXFileUploads: false,
            XLSXFileUploads: {
              ...(value?.XLSXFileUploads || {}),
              active: e.target.checked,
              strategy: value?.XLSXFileUploads?.strategy || 'asLineItemList',
            },
          })
        }
      >
        {t('Handle XLSX uploads')}
        <DCTooltip text={t('Allow Excel files to be uploaded.')} />
      </Checkbox>
      {value.XLSXFileUploads?.active && (
        <Card style={cardBorderCss}>
          <Form.Item label={t('Strategy')}>
            <CheckCard.Group
              onChange={(e: any) =>
                onValueChange({
                  ...value,
                  handleXLSXFileUploads: false,
                  XLSXFileUploads: {
                    ...(value.XLSXFileUploads || {}),
                    strategy: e,
                  },
                })
              }
              value={value?.XLSXFileUploads?.strategy || 'asLineItemList'}
            >
              <CheckCard
                title="As line item list"
                description="Every row as new line item."
                value="asLineItemList"
              />
              <CheckCard
                title="As PDF"
                description="Converts to PDF and extracts with AI"
                value="asPDF"
              />
            </CheckCard.Group>
          </Form.Item>
          <Form.Item label="Sheetname">
            <Input
              size="small"
              placeholder="Sheet 1 (optional)"
              value={value?.XLSXFileUploads?.sheetName || ''}
              onChange={(e) => {
                const strategy = e.target.value;
                onValueChange({
                  ...value,
                  handleXLSXFileUploads: false,
                  XLSXFileUploads: {
                    ...(value.XLSXFileUploads || {}),
                    sheetName: strategy,
                  },
                });
              }}
            />
          </Form.Item>
        </Card>
      )}
      <Checkbox
        className="hoverable"
        checked={value?.mergeMultipleUploads || false}
        onChange={(e) =>
          onValueChange({
            ...value,
            mergeMultipleUploads: e.target.checked,
          })
        }
      >
        {t('Merge uploads with same reference')}
        <DCTooltip
          text={t(
            'If multiple uploads with the same reference are uploaded, merge them into one customs case. If unchecked, Digicust will create a new customs case for each upload.',
          )}
        />
      </Checkbox>
      <Checkbox
        className="hoverable"
        checked={value?.forceReference === 'invoiceNumber'}
        onChange={(e) =>
          onValueChange({
            ...value,
            forceReference: e.target.checked ? 'invoiceNumber' : false,
            forceReferenceJSONata: '',
          })
        }
      >
        {t('Invoice number as reference')}
        <DCTooltip
          text={t(
            'Force the invoice number to be the reference of the customs case. If unchecked, Digicust will use the default reference (e.g. email subject)',
          )}
        />
      </Checkbox>
      <Checkbox
        className="hoverable"
        checked={value?.referenceInEmail}
        onChange={(e) =>
          onValueChange({
            ...value,
            referenceInEmail: e.target.checked,
          })
        }
      >
        {t('Reference in email')}
        <DCTooltip
          text={t(
            'If reference is specified in the email body, Digicust will use it as case reference.',
          )}
        />
      </Checkbox>
      <Checkbox
        className="hoverable"
        checked={!!value?.forceReferenceJSONata}
        onChange={(e) =>
          onValueChange({
            ...value,
            forceReferenceJSONata: e.target.checked
              ? 'reference & " - " & $substring($now(), 0,10)'
              : '',
            forceReference: false,
          })
        }
      >
        {t('Custom reference')}
        <DCTooltip
          text={t(
            'Customize the reference of the customs case. If unchecked, Digicust will use the default reference (e.g. email subject)',
          )}
        />
      </Checkbox>
      {value.forceReferenceJSONata && (
        <CardWrapper>
          {' '}
          <Form layout="vertical">
            <Form.Item
              label={t('JSONata Format String')}
              tooltip={t('The format string for the resulting case reference.')}
            >
              <JSONataInput
                inputValue={value?.forceReferenceJSONata}
                sampleInput={{
                  reference: 'Test Case',
                }}
                onChange={(e) =>
                  onValueChange({
                    ...value,
                    forceReferenceJSONata: e,
                  })
                }
              />
            </Form.Item>
          </Form>
        </CardWrapper>
      )}
      <Checkbox
        className="hoverable"
        checked={(value?.uploadWidgets?.length || 0) > 0}
        onChange={(e) => {
          if (!e.target.checked) {
            confirm({
              title: t('Are you sure you want to remove the widget?'),
              onOk: () => onValueChange({ ...value, uploadWidgets: [] }),
              okText: t('Yes'),
              maskClosable: true,
              onCancel: () => {
                console.log('test');
              },
              cancelText: t('No'),
            });
          } else {
            onValueChange({
              ...value,
              uploadWidgets: [{ form: 'export', id: nanoid(20) }],
            });
          }
        }}
      >
        {t('Embeddable upload form')}
        <DCTooltip
          text={t(
            'A form your clients can fill out to create customs cases directly to your inbox. Can also be embedded to your website.',
          )}
        />
      </Checkbox>
      {value.uploadWidgets?.length && (
        <Card style={cardBorderCss}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Form">
                <Select
                  popupMatchSelectWidth={false}
                  size="small"
                  showSearch
                  placeholder={t('Form')}
                  optionFilterProp="children"
                  value={value.uploadWidgets[0]?.form}
                  onChange={(e) =>
                    onValueChange({
                      ...value,
                      uploadWidgets: [
                        { form: e, ...(value?.uploadWidgets?.[0] || {}) },
                      ],
                    })
                  }
                >
                  <Select.Option value="export">{t('Export')}</Select.Option>
                  <Select.Option value="import">{t('Import')}</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Vendor name">
                <Input
                  size="small"
                  placeholder="Vendor name"
                  value={value?.uploadWidgets?.[0]?.vendor}
                  onChange={(e) => {
                    const newVendor = e.target.value;
                    onValueChange({
                      ...value,
                      uploadWidgets: [
                        {
                          ...(value?.uploadWidgets?.[0] || {}),
                          vendor: newVendor,
                        },
                        ...(value?.uploadWidgets?.slice?.(1) || []), // Keep any other items in the array unchanged
                      ],
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Terms">
                <TextArea
                  size="small"
                  placeholder="Terms"
                  value={value?.uploadWidgets?.[0]?.terms || ''}
                  onChange={(e) => {
                    const newTerms = e.target.value;
                    onValueChange({
                      ...value,
                      uploadWidgets: [
                        {
                          ...(value?.uploadWidgets?.[0] || {}),
                          terms: newTerms,
                        },
                        ...(value?.uploadWidgets?.slice?.(1) || []), // Keep any other items in the array unchanged
                      ],
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    {t('IFrame')}{' '}
                    <DCTooltip
                      text={t(
                        'Embed this form to your website to allow your clients to create customs cases directly to Digicust.',
                      )}
                    />
                  </>
                }
              >
                <Input
                  size="small"
                  value={`<iframe src="https://with.digicust.com/${value?.uploadWidgets?.[0]?.form}/${value?.uploadWidgets?.[0]?.id}" width="600" height="400"></iframe>`}
                  readOnly
                  suffix={
                    <Button
                      icon={<CopyOutlined />}
                      type="text"
                      size="small"
                      onClick={() =>
                        navigator.clipboard.writeText(
                          `<iframe src="https://with.digicust.com/${value?.uploadWidgets?.[0]?.form}/${value?.uploadWidgets?.[0]?.id}" width="600" height="400"></iframe>`,
                        )
                      }
                    />
                  }
                />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    {t('Form link')}{' '}
                    <DCTooltip
                      text={t(
                        'Share this link to allow your clients to create customs cases directly to Digicust.',
                      )}
                    />
                  </>
                }
              >
                <Input
                  size="small"
                  value={`https://with.digicust.com/${value?.uploadWidgets?.[0]?.form}/${value?.uploadWidgets?.[0]?.id}`}
                  readOnly
                  suffix={
                    <Button
                      icon={<CopyOutlined />}
                      type="text"
                      size="small"
                      onClick={() =>
                        navigator.clipboard.writeText(
                          `https://with.digicust.com/${value?.uploadWidgets?.[0]?.form}/${value?.uploadWidgets?.[0]?.id}`,
                        )
                      }
                    />
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Space direction="vertical">
                <>Preview</>
                <div
                  style={{
                    width: '350px',
                    height: '280px',
                    overflow: 'hidden',
                  }}
                >
                  <iframe
                    src={`https://with.digicust.com/${value?.uploadWidgets?.[0]?.form}/${value?.uploadWidgets?.[0]?.id}`}
                    width="350"
                    height="280"
                    style={{
                      border: 'none',
                      transform: 'scale(0.5)', // Adjust the scale as needed
                      transformOrigin: '0 0', // Ensures the iframe content is scaled from the top left corner
                      width: '700px', // Adjust the width to compensate for the scaling
                      height: '500px', // Adjust the height to compensate for the scaling
                    }}
                  ></iframe>
                </div>
              </Space>
            </Col>
          </Row>
        </Card>
      )}

      <Checkbox
        className="hoverable"
        checked={value?.importViaSFTP?.active || false}
        onChange={(e) =>
          onValueChange({
            ...value,
            importViaSFTP: {
              ...(value.importViaSFTP || {}),
              active: e.target.checked,
            },
          })
        }
      >
        {t('Upload from SFTP')}
        <DCTooltip
          text={t(
            'Upload customs cases to Digicust by placing PDF documents in a specific folder on an SFTP server. Digicust will periodically check for new documents and create customs cases automatically.',
          )}
        />
      </Checkbox>
      {value.importViaSFTP?.active && (
        <Card style={cardBorderCss}>
          <Form.Item label={t('DNS Name')} style={{ marginBottom: 5 }}>
            <Input
              size="small"
              placeholder={t('DNS Name')}
              value={value?.importViaSFTP?.['sftp-dns-name']}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  importViaSFTP: {
                    ...(value.importViaSFTP || {}),
                    'sftp-dns-name': e.target.value,
                  },
                })
              }
            />
          </Form.Item>
          <Form.Item label={t('Port')} style={{ marginBottom: 5 }}>
            <Input
              size="small"
              placeholder={t('Port')}
              value={value?.importViaSFTP?.['sftp-port']}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  importViaSFTP: {
                    ...(value.importViaSFTP || {}),
                    'sftp-port': e.target.value,
                  },
                })
              }
            />
          </Form.Item>
          <Form.Item label={t('Login Name')} style={{ marginBottom: 5 }}>
            <Input
              size="small"
              placeholder={t('Login Name')}
              value={value?.importViaSFTP?.['sftp-login-name']}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  importViaSFTP: {
                    ...(value.importViaSFTP || {}),
                    'sftp-login-name': e.target.value,
                  },
                })
              }
            />
          </Form.Item>
          <Form.Item label={t('Password')} style={{ marginBottom: 5 }}>
            <Input.Password
              size="small"
              placeholder={t('Password')}
              value={value?.importViaSFTP?.['sftp-password']}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  importViaSFTP: {
                    ...(value.importViaSFTP || {}),
                    'sftp-password': e.target.value,
                  },
                })
              }
            />
          </Form.Item>
          <Form.Item label={t('Inbound Path')} style={{ marginBottom: 5 }}>
            <Input
              size="small"
              placeholder={t('Inbound Path')}
              value={value?.importViaSFTP?.['sftp-inbound']}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  importViaSFTP: {
                    ...(value.importViaSFTP || {}),
                    'sftp-inbound': e.target.value,
                  },
                })
              }
            />
          </Form.Item>
          <Form.Item label={t('Outbound Path')} style={{ marginBottom: 5 }}>
            <Input
              size="small"
              placeholder={t('Outbound Path')}
              value={value?.importViaSFTP?.['sftp-outbound']}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  importViaSFTP: {
                    ...(value.importViaSFTP || {}),
                    'sftp-outbound': e.target.value,
                  },
                })
              }
            />
          </Form.Item>
        </Card>
      )}
    </CardWrapper>
  );
};
