import { message } from 'antd';
import axios from 'axios';
import { UserModel } from 'digicust_types';
import { GENERIC_URL } from './constants';

export class UserRepository {
  public static getUser = async (userId?: string): Promise<UserModel> => {
    try {
      const response = await axios.get(`${GENERIC_URL}/api/users/${userId}`);
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return {};
    }
  };
}
