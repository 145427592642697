import { CustomsTariffNumber, TariffNumberSuggestion } from 'digicust_types';
import React, { useEffect, useState } from 'react';
import { TariffSuggestionTagBox } from '../TariffClassification';
import { sortTariffSuggestions } from '../TariffClassification/helpers/sort-suggestions';

interface TariffSelectSuggestionProps {
  customsTariffNumber?: CustomsTariffNumber | null;
  onChange: (value: CustomsTariffNumber | null) => void;
}

const TariffSelectSuggestion: React.FC<TariffSelectSuggestionProps> = ({
  customsTariffNumber,
  onChange,
}) => {
  const [suggestions, setSuggestions] = useState<TariffNumberSuggestion[]>([]);

  useEffect(() => {
    setSuggestions(customsTariffNumber?.suggestions || []);
  }, [customsTariffNumber]);

  const handleSuggestionAccept = () => {
    const [firstSuggestion, ...newSuggestions] = suggestions;
    if (firstSuggestion) {
      const tariff = firstSuggestion.tariffNumber;
      const sorted = sortTariffSuggestions(newSuggestions);

      setSuggestions(sorted);
      onChange({
        ...(customsTariffNumber || {}),
        suggestions: sorted,
        hsCode: { value: tariff, input: tariff },
        value: tariff,
        input: tariff,
        taric: { processedByTaric: true },
      });
    }
  };

  const handleSuggestionCancel = () => {
    if (suggestions.length > 0) {
      const newSuggestions = suggestions.map((suggestion, index) =>
        index === 0 ? { ...suggestion, rejected: true } : suggestion,
      );
      const sorted = sortTariffSuggestions(newSuggestions);

      setSuggestions(sorted);
      onChange({
        ...(customsTariffNumber || {}),
        suggestions: sorted,
      });
    }
  };

  const hasValidSuggestion = suggestions.filter((c) => !c.rejected).length > 0;

  return hasValidSuggestion ? (
    <TariffSuggestionTagBox
      suggestion={suggestions.filter((c) => !c.rejected)?.[0]}
      onAccept={handleSuggestionAccept}
      onCancel={handleSuggestionCancel}
    />
  ) : null;
};

export default TariffSelectSuggestion;
