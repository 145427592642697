import * as XLSX from 'xlsx';

export const downloadJSONAsXLSX = (jsonData: any[], fileName: string) => {
  // Convert the JSON array to a worksheet
  const worksheet = XLSX.utils.json_to_sheet(jsonData);

  // Create a new workbook and add the worksheet with a name
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Define the file name and extension
  fileName = fileName || 'download.xlsx';

  // Trigger the download
  XLSX.writeFile(workbook, fileName);
};
