import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StakeholderModel } from 'digicust_types';

export interface StakeholderReduxState {
  data: StakeholderModel[];
  selectedKey?: string;
}

export const initialStakeholderState: StakeholderReduxState = {
  data: [],
};

const stakeholderSlice = createSlice({
  name: 'stakeholder',
  initialState: initialStakeholderState,
  reducers: {
    updateStakeholderData: (
      state,
      action: PayloadAction<StakeholderModel[]>,
    ) => {
      state.data = action.payload;
    },
    stakeholderSelectedKey: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.selectedKey = action.payload;
    },
  },
});

export const { updateStakeholderData, stakeholderSelectedKey } =
  stakeholderSlice.actions;
export default stakeholderSlice.reducer;
