import { Route, Routes } from 'react-router-dom';

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { Button, Layout, Space } from 'antd';
import React, { Suspense } from 'react';

import { config } from '../config';

import FullPageLoader from '../common/FullPageLoader';
import AppDataProvider from './AppDataProvider';
import CustomerProjectSpecificRoutes from './CustomerProjectSpecificRoutes';

import ChooseCustomer from './ChooseCustomer/ChooseCustomer';

const Portal: React.FC = () => {
  const { instance } = useMsal();

  return (
    <>
      <AuthenticatedTemplate>
        <Layout
          style={{
            minHeight: '100vh',
            maxHeight: '100vh',
            minWidth: '100vw',
            maxWidth: '100vw',
            overflowY: 'hidden',
          }}
        >
          <Suspense fallback={<FullPageLoader />}>
            <Routes>
              <Route index element={<ChooseCustomer />} />
              <Route
                path="/:customerId/:projectId/*"
                element={
                  <AppDataProvider>
                    <CustomerProjectSpecificRoutes />
                  </AppDataProvider>
                }
              ></Route>
            </Routes>
          </Suspense>
        </Layout>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Layout style={{ minHeight: '100vh', alignItems: 'center' }}>
          <Space>
            <p> Please sign-in to see your profile information.</p>
            <Button
              onClick={() => {
                instance.loginPopup(config.msalRequest).catch((e) => {
                  console.error(e);
                });
              }}
            >
              Sign in
            </Button>
          </Space>
        </Layout>
      </UnauthenticatedTemplate>
    </>
  );
};

export default Portal;
