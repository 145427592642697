import { Card, Tabs } from 'antd';
import { TariffSystem, UserInput } from 'digicust_types';
import CustomValidationMessagesComponent from '../../../Components/Inputs/CustomValidationMessagesComponent ';
import { useAppDataContext } from '../../AppDataProvider';
import SearchableCaseFields from '../aggregated-field-mapper';
import { SearchableLineItems } from '../line-items-tab/tableViews/useSearchableLineItems';

export default function CaseDetailsSearchable({
  userInput,
  onChange,
}: {
  userInput: UserInput;
  onChange: (caseData: UserInput) => void;
}) {
  const { projectDetails } = useAppDataContext();
  return (
    <Card>
      <Tabs
        items={[
          {
            key: 'CaseDetails',
            label: 'Case Details',
            children: (
              <div>
                <SearchableCaseFields
                  caseData={userInput.annotatedAggregated || {}}
                  updateItem={(annotatedAggregated) => {
                    onChange({
                      ...(userInput || {}),
                      annotatedAggregated,
                    });
                  }}
                />
              </div>
            ),
          },
          {
            key: 'LineItems',
            label: 'Line Items',
            children: (
              <div>
                <SearchableLineItems
                  item={userInput.annotatedAggregated?.items?.[0] || {}}
                  updateItem={(item) =>
                    onChange({
                      ...(userInput || {}),
                      annotatedAggregated: {
                        ...(userInput.annotatedAggregated || {}),
                        items: [item],
                      },
                    })
                  }
                  tariffSystem={
                    projectDetails?.tariffNumberTreeSystem || TariffSystem.DE
                  }
                />
              </div>
            ),
          },
          {
            key: 'ExecutionStrategy',
            label: 'Execution Strategy',
            children: <div>ExecutionStrategy</div>,
          },
          {
            key: 'Warnings',
            label: 'Warnings',
            children: (
              <div>
                <CustomValidationMessagesComponent
                  value={userInput?.customValidationMessages || []}
                  onValueChange={(customValidationMessages) => {
                    onChange({
                      ...(userInput || {}),
                      customValidationMessages,
                    });
                  }}
                />
              </div>
            ),
          },
        ]}
      />
    </Card>
  );
}
