import productionConfig from './production-config';
import stagingConfig from './staging-config';

const stage = process.env.REACT_APP_ENVIRONMENT;
const local = process.env.REACT_APP_WITH_LOCAL_GENERIC;
console.log({ stage, local });

export const config =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? productionConfig
    : stagingConfig;
