import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  UilCar,
  UilMetro,
  UilPlaneDeparture,
  UilShip,
  UilTruck,
} from '@iconscout/react-unicons';
import { Button, Select, Space, Typography } from 'antd';
import { MeanOfTransportation, MeanOfTransportationType } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import DGTable from '../../../common/DGTable';
import DebouncedInput from '../../../Components/Inputs/Common/DebouncedInput';
import CountrySelect from '../../../Components/Inputs/CountrySelect';
import MeanOfTransportationModeSelect from '../../../Components/Inputs/MeanOfTransportationModeSelect';

const MeansOfTransportation = ({
  value = [],
  onValueChange,
}: {
  value: MeanOfTransportation[];
  onValueChange: (value: MeanOfTransportation[]) => void;
}) => {
  const { t } = useTranslation();

  const updateItem = (document: MeanOfTransportation, index: number) => {
    if (typeof index === 'number') {
      const newValue = [...value];
      newValue[index] = document;
      onValueChange(newValue);
    }
  };

  return (
    <div>
      <DGTable<MeanOfTransportation>
        headerTitle={t('Means of Transportation')}
        dataSource={value || []}
        rightToolbar={
          <Button
            onClick={() => {
              onValueChange([...value, {}]);
            }}
            key="button"
            icon={<PlusOutlined />}
          >
            {t('Add')}
          </Button>
        }
        showHeader={false}
        columns={[
          {
            getValue: (document) => document?.type || '',
            title: t('Type'),
            key: 'type',
            fixed: 'left',
            width: '150px',
            render: (_, item, index) => (
              <Select
                variant="filled"
                style={{ width: '100%' }}
                showSearch
                popupMatchSelectWidth={200}
                placeholder={t('Select Type')}
                value={item?.type}
                onChange={(e) => {
                  updateItem({ ...item, type: e }, index);
                }}
              >
                {[
                  {
                    label: t('Border'),
                    value: MeanOfTransportationType.border,
                  },
                  {
                    label: t('Arrival'),
                    value: MeanOfTransportationType.arrival,
                  },
                  {
                    label: t('Departure'),
                    value: MeanOfTransportationType.departure,
                  },
                  {
                    label: t('Inland'),
                    value: MeanOfTransportationType.inland,
                  },
                ]?.map((item, key) => (
                  <Select.Option
                    title={item?.label}
                    key={key}
                    value={item?.value}
                    label={item?.label}
                  >
                    <Space>
                      <Typography.Text
                        style={{ maxWidth: '400px', display: 'flex' }}
                        ellipsis
                      >
                        {item.label}
                      </Typography.Text>
                    </Space>
                  </Select.Option>
                ))}
              </Select>
            ),
          },
          {
            getValue: (document) => document?.value || '',
            title: t('Mode'),
            key: 'mode',
            width: '150px',
            render: (_, item, index) => (
              <MeanOfTransportationModeSelect
                value={item}
                onChange={(e) => {
                  updateItem({ ...item, ...(e || {}) }, index);
                }}
              />
            ),
          },
          {
            getValue: (document) => document?.DE_A1140 || '',
            title: t('Vehicle'),
            key: 'vehicle',
            width: '150px',
            render: (_, item, index) => {
              const vehicleOptions = [
                {
                  label: t('Truck'),
                  value: '01',
                  DE_A0108: '30',
                  icon: <UilTruck size="14" />,
                },
                {
                  label: t('Ship'),
                  value: '02',
                  DE_A0108: '10',
                  icon: <UilShip size="14" />,
                },
                {
                  label: t('Train'),
                  value: '03',
                  DE_A0108: '20',
                  icon: <UilMetro size="14" />,
                },
                {
                  label: t('Airplane'),
                  value: '04',
                  DE_A0108: '40',
                  icon: <UilPlaneDeparture size="14" />,
                },
                {
                  label: t('Car'),
                  value: '05',
                  DE_A0108: '31',
                  icon: <UilCar size="14" />,
                },
                {
                  label: t('Without'),
                  value: '06',
                },
                {
                  label: t('Other'),
                  value: '07',
                },
              ];

              return (
                <Select
                  variant="filled"
                  style={{ width: '100%' }}
                  showSearch
                  popupMatchSelectWidth={200}
                  placeholder={t('Select Shipping Type')}
                  value={item?.DE_A1140}
                  onChange={(e) => {
                    const selectedItem = vehicleOptions.find(
                      (option) => option.value === e,
                    );
                    updateItem(
                      {
                        ...item,
                        DE_A1140: e,
                        DE_A0108: selectedItem?.DE_A0108,
                      },
                      index,
                    );
                  }}
                >
                  {vehicleOptions?.map((item, key) => (
                    <Select.Option
                      title={item?.value}
                      key={key}
                      value={item?.value}
                      label={item?.label}
                    >
                      <Space>
                        <Typography.Text strong>
                          <span>{item?.icon}</span>{' '}
                        </Typography.Text>
                        <Typography.Text
                          style={{ maxWidth: '400px', display: 'flex' }}
                          ellipsis
                        >
                          {item.label}
                        </Typography.Text>
                      </Space>
                    </Select.Option>
                  ))}
                </Select>
              );
            },
          },
          {
            getValue: (document) => document?.licensePlate || '',
            title: t('License Plate'),
            key: 'licensePlate',
            width: '150px',
            render: (_, item, index) => (
              <DebouncedInput
                placeholder={t('License Plate')}
                value={item?.licensePlate}
                onChange={(licensePlate) => {
                  updateItem({ ...(item || {}), licensePlate }, index);
                }}
              />
            ),
          },
          {
            getValue: (document) => document?.countryCode || '',
            title: t('Nationality'),
            key: 'nationality',
            width: '150px',
            render: (_, item, index) => (
              <CountrySelect
                value={
                  item?.countryCode
                    ? {
                        alpha2Code: item?.countryCode,
                      }
                    : undefined
                }
                onChange={(e) =>
                  updateItem(
                    {
                      ...(item || {}),
                      countryCode: e?.alpha2Code || '',
                    },
                    index,
                  )
                }
              />
            ),
          },
          {
            title: 'Actions',
            key: 'actions',
            width: '30px',
            render: (_, item, index) => (
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => {
                  const newValue = [...value];
                  newValue.splice(index, 1);
                  onValueChange(newValue);
                }}
              ></Button>
            ),
          },
        ]}
      />
    </div>
  );
};

export default MeansOfTransportation;
