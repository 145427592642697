import { message } from 'antd';
import {
  CustomsTariffNumber,
  DigicustDocumentModel,
  LineItemModel,
  Meta,
  PreferenceState,
  Procedure,
  Translatable,
} from 'digicust_types';
import { useState } from 'react';
import getUniqueObjects from '../../../../utils/helpers/getUniqueObjects';

export type CopiedData = {
  description?: Translatable;
  customsTariffNumber?: CustomsTariffNumber;
  additionalCodes?: Meta<string>[];
  preferenceState?: PreferenceState;
  documents?: DigicustDocumentModel[];
  customsProcedure?: Procedure;
};

type CopiedIndex = {
  key?: string | 'fallback';
  description?: string | 'fallback';
  customsTariffNumber?: string | 'fallback';
  additionalCodes?: string | 'fallback';
  preferenceState?: string | 'fallback';
  documents?: string | 'fallback';
  customsProcedure?: string | 'fallback';
};

const defaultCopiedIndex: CopiedIndex = {
  key: 'fallback',
  description: 'fallback',
  customsTariffNumber: 'fallback',
  additionalCodes: 'fallback',
  preferenceState: 'fallback',
  documents: 'fallback',
  customsProcedure: 'fallback',
};

export default function useCopyHook({
  lineItems,
  onPaste = () => null,
}: {
  lineItems: LineItemModel[];
  onPaste?: (newLineItems: LineItemModel[]) => void;
}) {
  const [copiedData, setCopiedData] = useState<CopiedData>({});

  const [copiedIndex, setCopiedIndex] =
    useState<CopiedIndex>(defaultCopiedIndex);

  const clearCopiedData = () => {
    setCopiedData({});
    setCopiedIndex(defaultCopiedIndex);
  };

  const handleCopy = (value: Record<string, any>, lineItemId?: string) => {
    const key = Object.keys(value)[0];

    if ((copiedIndex as any)[key] === lineItemId) {
      setCopiedData((prev) => ({ ...prev, [key]: null }));
      setCopiedIndex((prev) => ({ ...prev, [key]: 'fallback' }));
      message.info('Uncopied');
    } else {
      setCopiedData((prev) => ({ ...prev, ...value }));
      setCopiedIndex((prev) => ({ ...prev, [key]: lineItemId }));
      message.info('Copied');
    }
  };

  const handlePaste = async (items: LineItemModel[]) => {
    const newValue = [...lineItems];

    const keys = Object.keys(copiedData);

    items.forEach((curItem) => {
      let updatedItem = { ...curItem };

      const commonWorkflow = {
        userEdited: true,
        source: 'copied-line-item',
        bbox: null,
        confidence: null,
        modifiedAt: new Date().getTime(),
      };

      keys?.forEach((key) => {
        const currentCopiedData = (copiedData as any)[key];

        // eslint-disable-next-line no-prototype-builtins
        if (copiedData.hasOwnProperty(key)) {
          if (key === 'additionalCodes') {
            updatedItem = {
              ...updatedItem,
              customsTariffNumber: {
                ...updatedItem.customsTariffNumber,
                additionalCodes: currentCopiedData,
              },
            };
          } else if (key === 'customsTariffNumber') {
            updatedItem = {
              ...updatedItem,
              customsTariffNumber: {
                ...currentCopiedData,
                additionalCodes:
                  updatedItem.customsTariffNumber?.additionalCodes,
              },
            };
          } else if (key === 'documents') {
            updatedItem = {
              ...updatedItem,
              documents: getUniqueObjects(
                [...(updatedItem.documents || []), ...currentCopiedData],
                'documentCode.DE_I0200',
              ),
            };
          }
          // If data is an Array
          else if (Array.isArray(currentCopiedData)) {
            updatedItem = {
              ...updatedItem,
              [key]: [
                ...((updatedItem as any)[key] || []),
                ...(currentCopiedData || []),
              ].map((cur) => ({
                ...cur,
                ...commonWorkflow,
              })),
            };
          }
          // If data is an object
          else if (typeof currentCopiedData === 'object') {
            updatedItem = {
              ...updatedItem,
              ...(currentCopiedData
                ? {
                    [key]: {
                      ...(updatedItem as any)[key],
                      ...currentCopiedData,
                      ...commonWorkflow,
                    },
                  }
                : {}),
            };
          }
          // if data is not array or object, just copy exact value
          else {
            updatedItem = { ...updatedItem, [key]: currentCopiedData };
          }
        }
      });

      const index = newValue.findIndex((item) => item.id === updatedItem.id);

      if (index >= 0) {
        newValue[index] = updatedItem;
      }
    });

    clearCopiedData();
    onPaste(newValue);
  };

  return {
    handleCopy,
    handlePaste,
    copiedData,
    setCopiedData,
    copiedIndex,
    setCopiedIndex,
    clearCopiedData,
  };
}
