import { Button, message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TariffClassificationService } from '../../../utils/services/tariff-classification.service';
import { useAppDataContext } from '../../AppDataProvider';

export default function ClassifyLineItemButton({
  lineItemIds,
  onSuccess = () => null,
}: {
  lineItemIds: { id: string; barCode?: string }[];
  onSuccess?: (lineItemIds: string[]) => void;
}) {
  const { customerId, projectId, projectDetails } = useAppDataContext();

  const { id } = useParams();
  const { t } = useTranslation();

  const [classifyLoading, setClassifyLoading] = useState<boolean>(false);

  const triggerClassification = async () => {
    setClassifyLoading(true);

    try {
      const result = await TariffClassificationService.classifyLineItems({
        customerId: customerId!,
        projectId: projectId!,
        caseId: id!,
        lineItemIds: lineItemIds,
        tariffSystem: projectDetails?.tariffNumberTreeSystem,
      });

      onSuccess(lineItemIds.map((key) => key.id));
      message.success(result);
    } catch (error: any) {
      message.error(error.response.data || 'Classification failed.');
    }

    setClassifyLoading(false);
  };

  return (
    <Button
      type="text"
      size="small"
      title="Classify"
      loading={classifyLoading}
      onClick={triggerClassification}
    >
      {t('Classify')}
    </Button>
  );
}
