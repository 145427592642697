import { List, Typography } from 'antd';
import {
  ActionImpl,
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarResults,
  KBarSearch,
  useMatches,
} from 'kbar';
import React from 'react';

const KBar = () => {
  const RenderResults = () => {
    const { results } = useMatches();

    const RenderItem = ({
      item,
      active,
      isParent,
    }: {
      item: ActionImpl;
      active: boolean;
      isParent?: boolean;
    }) => {
      return (
        <List.Item
          style={{
            cursor: 'default',
            background: active ? '#fafafa' : 'transparent',
          }}
          actions={[
            ...(item.command
              ? [
                  item.shortcut?.map((shortCutButton) => (
                    <Typography.Text keyboard>{shortCutButton}</Typography.Text>
                  )),
                ]
              : []),
          ]}
        >
          <List.Item.Meta
            avatar={
              !isParent && (
                <span
                  style={{
                    fontSize: '20pt',
                    color: 'grey',
                    marginLeft: 10,
                  }}
                >
                  {item.icon}
                </span>
              )
            }
            title={
              <div>
                {item.ancestors.length > 0 &&
                  item.ancestors.map((ancestor) => (
                    <React.Fragment key={ancestor.id}>
                      <span
                        style={{
                          opacity: 0.5,
                          marginRight: 8,
                          marginLeft: 10,
                        }}
                      >
                        {ancestor.name}
                      </span>
                      <span
                        style={{
                          marginRight: 8,
                          marginLeft: 10,
                        }}
                      >
                        &rsaquo;
                      </span>
                    </React.Fragment>
                  ))}
                <span>{item.name}</span>
              </div>
            }
            description={!isParent && item.subtitle}
          />
        </List.Item>
      );
    };

    return (
      <List style={{ height: '100%' }}>
        <KBarResults
          items={results}
          onRender={({ item, active }) => {
            if (typeof item === 'string') {
              return (
                <div
                  style={{
                    padding: '10px 25px',
                    fontWeight: '600',
                    color: 'rgba(0,0,0,.45)',
                  }}
                >
                  {item}
                </div>
              );
            } else {
              return (
                <div>
                  <RenderItem item={item} active={active} />
                </div>
              );
            }
          }}
        />
      </List>
    );
  };

  return (
    <KBarPortal>
      <KBarPositioner
        style={{
          zIndex: 1000,
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.45)',
        }}
      >
        <KBarAnimator
          style={{
            position: 'relative',
            backgroundColor: '#fff',
            backgroundClip: 'padding-box',
            border: 0,
            borderRadius: '8px',
            boxShadow:
              '0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
            pointerEvents: 'auto',
            minHeight: '300px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'inline-flex',
              width: '100%',
              minWidth: 0,
              padding: 0,
              color: 'rgba(0, 0, 0, 0.88)',
              fontSize: '14px',
              lineHeight: 1.5714285714285714,
              backgroundColor: '#ffffff',
              backgroundImage: 'none',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: '#d9d9d9',
              borderRadius: '6px',
              transition: 'all 0.2s',
            }}
          >
            <KBarSearch
              style={{
                padding: '15px 20px',
                width: '500px',
                maxWidth: '80vw',
                fontSize: 'inherit',
                border: 'none',
                borderRadius: 0,
                outline: 'none',
              }}
            />
          </div>
          <RenderResults />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
};

export default KBar;
