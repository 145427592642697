import React, { useEffect } from 'react';

const Page = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactElement;
}) => {
  useEffect(() => {
    document.title = `${title} | Digicust`;
  }, [title]);

  return children;
};

export default Page;
