import { Card, Space, Switch, Typography } from 'antd';
import { DCTooltip } from '../../../common';
import { ReactNode } from 'react';

export const cardBorderCss = {
  margin: 10,
  width: '95%',
  borderLeft: '5px #1677ff solid',
};

export const cardBorderAlternativeCss = {
  // margin: 10,
  // width: '95%',
  // borderLeft: '5px #1677ff solid',
};

export default function CardWrapper({
  title,
  description,
  tooltip,
  checked,
  onChange,
  children,
  alternative,
}: {
  title?: string;
  description?: string;
  tooltip?: string;
  checked?: boolean;
  onChange?: (val: boolean) => void;
  children?: ReactNode;
  alternative?: boolean;
}) {
  return (
    <Card
      bordered
      style={alternative ? cardBorderAlternativeCss : cardBorderCss}
    >
      <Space direction="vertical" size={0} style={{ marginBottom: 16 }}>
        <Space>
          {title && (
            <Typography.Title level={5} style={{ whiteSpace: 'nowrap' }}>
              {title}
              {tooltip && <DCTooltip text={tooltip} />}
            </Typography.Title>
          )}

          {onChange && !checked && (
            <Switch checked={checked} onChange={(e) => onChange(e)} />
          )}
        </Space>
        {description && <Typography.Text>{description}</Typography.Text>}
      </Space>
      {children}
    </Card>
  );
}
