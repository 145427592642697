import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';

export default function DGTitle({
  label,
  loading,
  onEdit,
  copiable,
}: {
  label: string;
  loading?: boolean;
  onEdit?: (value: string | undefined) => void;
  copiable?: boolean;
}) {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState<string>();

  useEffect(() => setValue(label), [label]);

  return (
    <Flex gap={5} justify="center">
      {loading && <LoadingOutlined />}
      {!edit ? (
        <Typography.Title copyable={copiable} level={5}>
          {label}
        </Typography.Title>
      ) : (
        <Input
          size="small"
          value={value}
          autoFocus
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => {
            onEdit && onEdit(value);
            setEdit(false);
          }}
        />
      )}

      <Space.Compact>
        {onEdit && (
          <Button
            size="small"
            type="link"
            icon={<EditOutlined />}
            onClick={() => setEdit((p) => !p)}
            title="Edit"
          />
        )}
        {/* {copiable && (
          <Button
            size="small"
            type="link"
            icon={<CopyOutlined />}
            title="Copy"
          />
        )} */}
      </Space.Compact>
    </Flex>
  );
}
