import { useAccount, useMsal } from '@azure/msal-react';
import { UserModel } from 'digicust_types';
import { useEffect, useState } from 'react';
import { UserRepository } from './repositories/user.repository';

/**
 * @deprecated please use useAppDataContext()
 */
export const useUser = () => {
  const [user, setUser] = useState<UserModel>({});
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  useEffect(() => {
    UserRepository.getUser(account?.localAccountId).then((user) => {
      setUser(user);
    });
  }, [account]);
  return { isAdmin: user?.role === 'admin', user };
};
