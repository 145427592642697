import { CheckCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Descriptions, Popover, Tag, Typography } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { VATNumber } from 'digicust_types';
import { useTranslation } from 'react-i18next';

export const VATNumberValidation = ({
  vatNumber,
}: {
  vatNumber?: VATNumber;
}) => {
  const { t } = useTranslation();

  return (
    <Popover
      title={`${t('VAT Validation')} ${vatNumber?.value}`}
      content={
        <Paragraph style={{ maxWidth: '400px' }}>
          <Descriptions column={1} size="small" bordered={true}>
            <Descriptions.Item label={t('Validity')}>
              <Tag color={vatNumber?.valid ? 'success' : 'warning'}>
                {vatNumber?.valid ? t('Valid') : t('Invalid')}
              </Tag>
            </Descriptions.Item>
            {vatNumber?.validationInformation?.validationSource && (
              <Descriptions.Item label={t('Source')}>
                <Typography.Text
                  style={{ maxWidth: '180px' }}
                  title={vatNumber?.validationInformation?.validationSource}
                >
                  {vatNumber?.validationInformation?.validationSource}
                </Typography.Text>
              </Descriptions.Item>
            )}
            {vatNumber?.validationInformation?.name && (
              <Descriptions.Item label={t('Name')}>
                <Typography.Text
                  style={{ maxWidth: '180px' }}
                  title={vatNumber?.validationInformation?.name}
                >
                  {vatNumber?.validationInformation?.name}
                </Typography.Text>
              </Descriptions.Item>
            )}
            {vatNumber?.validationInformation?.address && (
              <Descriptions.Item label={t('Address')}>
                <Typography.Text
                  style={{ maxWidth: '180px' }}
                  title={vatNumber?.validationInformation?.address}
                >
                  {vatNumber?.validationInformation?.address}
                </Typography.Text>
              </Descriptions.Item>
            )}
          </Descriptions>
        </Paragraph>
      }
    >
      {vatNumber?.valid ? (
        <Tag color="success">
          <CheckCircleOutlined />
        </Tag>
      ) : vatNumber?.valid === false ? (
        <Tag color="warning">
          <CloseOutlined />
        </Tag>
      ) : (
        ''
      )}
    </Popover>
  );
};
