import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Flex, Popover, Space, Tag, Typography } from 'antd';
import { TariffNumberSuggestion } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { TariffNumberDescription } from '../../Pages/MasterData/Components/TableTariffNumberComponent';
import { formatTariffNumber } from '../../utils/helpers/formatTariffNumber';
import TariffNumberIcon from '../Display/TariffNumberIcon';

const getSuggestionColor = (confidence?: number) => {
  if (!confidence) return 'green';
  if (confidence < 90) return 'red';
  if (confidence < 97) return 'yellow';
  return 'green';
};

export function TariffSuggestionTagBox({
  suggestion,
  inline = false,
  onAccept,
  onCancel,
}: {
  suggestion: TariffNumberSuggestion;
  inline?: boolean;
  onAccept: (suggestion: TariffNumberSuggestion) => void;
  onCancel: (suggestion: TariffNumberSuggestion) => void;
}) {
  const { t } = useTranslation();

  return (
    <Tag
      color={getSuggestionColor(suggestion?.confidence)}
      style={
        !inline
          ? { padding: 5, margin: 0, width: '100%' }
          : { padding: 2, margin: 0 }
      }
    >
      <Space direction="vertical" style={{ width: '100%' }} size={1}>
        <Flex justify="space-between" align="center" gap={1}>
          <Popover
            content={
              suggestion?.confidence ? (
                <Space direction="vertical" size={0}>
                  <Typography.Text>
                    {t('Confidence')}
                    {`: ${suggestion?.confidence}`}
                  </Typography.Text>
                  <Typography.Paragraph
                    type="secondary"
                    style={{ maxWidth: 300 }}
                    ellipsis={{ rows: 1, expandable: true }}
                  >
                    {suggestion?.reason}
                  </Typography.Paragraph>
                </Space>
              ) : null
            }
          >
            <Flex align="center" gap={4}>
              <TariffNumberIcon tariffNumber={suggestion?.tariffNumber || ''} />
              <Typography.Text style={{ maxWidth: 150 }} ellipsis>
                {formatTariffNumber(suggestion?.tariffNumber)}
              </Typography.Text>
            </Flex>
          </Popover>

          {suggestion.rejected ? (
            <Flex gap={5}>
              <Typography.Text type="secondary">
                {t('Rejected')}
              </Typography.Text>
              <Button
                onClick={() => onAccept(suggestion)}
                type="text"
                size="small"
                icon={<CheckOutlined style={{ color: 'green' }} />}
              />
            </Flex>
          ) : (
            <Space.Compact>
              <Button
                onClick={() => onAccept(suggestion)}
                type="text"
                size="small"
                icon={<CheckOutlined style={{ color: 'green' }} />}
              />
              <Button
                onClick={() => onCancel(suggestion)}
                type="text"
                size="small"
                icon={<CloseOutlined style={{ color: 'red' }} />}
              />
            </Space.Compact>
          )}
        </Flex>

        {!inline && (
          <>
            {suggestion?.tariffNumber && (
              <TariffNumberDescription
                tariffNumber={suggestion?.tariffNumber}
              />
            )}
            {suggestion?.reason && (
              <Typography.Paragraph
                italic
                type="secondary"
                ellipsis={{ rows: 1, expandable: true }}
                style={{ width: '100%', whiteSpace: 'break-spaces' }}
              >
                <strong>{t('Reason')}:</strong> {suggestion?.reason}
              </Typography.Paragraph>
            )}
          </>
        )}
      </Space>
    </Tag>
  );
}
