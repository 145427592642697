import { Button, Card, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatTariffNumber } from '../../utils/helpers/formatTariffNumber';
import { useTariffNumbers } from '../../utils/useCodes';

export const ChatTariffNumber = ({
  number,
  onChange = () => null,
  apply,
}: {
  number: string;
  onChange?: (value: string) => void;
  apply?: boolean;
}) => {
  const tariffNumbers = useTariffNumbers();
  const tariffNumber = tariffNumbers.find((num) => num.code === number);

  const { t } = useTranslation();
  return (
    <Card bodyStyle={{ padding: 10 }} style={{ marginTop: 10 }}>
      <Space direction="vertical" size={0}>
        <Space>
          <Typography.Title copyable={{ text: number }} level={4}>
            {formatTariffNumber(number)}
          </Typography.Title>
          {apply && (
            <Button size="small" onClick={() => onChange(number)}>
              {t('Apply')}
            </Button>
          )}
        </Space>

        <Typography.Text type="secondary">
          {tariffNumber?.description}
        </Typography.Text>
      </Space>
    </Card>
  );
};
