import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Drawer, Space, Steps } from 'antd';
import { CustomerModel, ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { ExecutionStrategyRepository } from '../../../utils/repositories/executionStrategy.repository';
import { OnboardingRepository } from '../../../utils/repositories/onboarding.repository';
import CustomerComponent from '../../Settings/Customer/Customer';
import ExecutionStrategyComponent from '../../Settings/ExecutionStrategies/ExecutionStrategyDetails';
import { setCreateCompany } from '../../../Components/reduxToolkit/statisticsSlice';

const defaultData: CustomerModel = {
  name: '',

  companyAddress: '',
  country: 'DE',
  city: '',
  zipCode: '',
  commercialRegistryEntry: '',
  taxNumber: '',
  vatNumber: '',
  flags: [],
  eoriNumber: '',

  contactPerson: {
    name: '',
    email: '',
    address: '',
    phone: '',
  },
  typeOfCompany: 'forwarder',

  typeOfIndustry: '',
  companySize: '',
  numberOfCustomers: '',
  useOfSoftware: '',
  importsPerYear: '',
  exportsPerYear: '',
  specialProcedures: [],
  specialProceduresDeclarationPerYear: '',
};

const NewCustomerModal = (props: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) => {
  const [newCustomerId, setNewCustomerId] = useState('');
  const [newProjectId, setNewProjectId] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [executionStrategy, setExecutionStrategy] =
    useState<ExecutionStrategy>();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [customerData, setCustomerData] = useState<CustomerModel>(defaultData);

  const createCustomer = async () => {
    setLoading(true);
    const customer = await OnboardingRepository.createCustomer(customerData);
    console.log(customer);
    const { customerId, projectId } = customer;
    if (customerId && projectId) {
      setNewCustomerId(customerId);
      setNewProjectId(projectId);
      const executionStrategies = await ExecutionStrategyRepository.getList(
        customerId,
        projectId,
        {},
      );
      if (executionStrategies?.[0]?.id) {
        const newExecutionStrategy = await ExecutionStrategyRepository.get(
          customerId,
          projectId,
          executionStrategies?.[0]?.id,
        );
        setExecutionStrategy(newExecutionStrategy);
        setStep(1);
        setLoading(false);
      }
    }
  };

  const saveExecutionStrategy = async () => {
    setLoading(true);
    await ExecutionStrategyRepository.save(
      newCustomerId,
      newProjectId,
      executionStrategy,
    );
    window.location.href = `/${newCustomerId}/${newProjectId}`;
    props.onOpenChange(false);
    setLoading(false);
  };

  const buttonValues: {
    [step: number]: { onClick: () => void; label: string };
  } = {
    0: { onClick: createCustomer, label: t('Create Customer') },
    1: { onClick: saveExecutionStrategy, label: t('Save Execution Strategy') },
  };

  useEffect(() => {
    dispatch(setCreateCompany(props?.open));
  }, [props?.open]);

  return (
    <Drawer
      width={800}
      title={t('Create Company')}
      open={props.open}
      placement="right"
      footer={
        <div
          style={{
            width: '100%',
            padding: '10px',
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Button
            type="primary"
            loading={loading}
            onClick={buttonValues[step].onClick}
            disabled={!customerData.name}
          >
            {buttonValues[step].label}
          </Button>
        </div>
      }
      onClose={() => props.onOpenChange(false)}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Steps
          current={step}
          items={[
            {
              title: t('Company Details'),
              description: t('Tell us about your company'),
            },
            {
              title: t('Execution Strategy'),
              description: t('Specify how customs cases get processed'),
            },
            {
              title: t('Finished'),
              description: t('Welcome to Digicust!'),
            },
          ]}
        />
        <br />
        {step === 0 ? (
          <CustomerComponent value={customerData} onChange={setCustomerData} />
        ) : step === 1 ? (
          <ExecutionStrategyComponent
            value={executionStrategy || {}}
            onValueChange={setExecutionStrategy}
          />
        ) : null}
      </Space>
    </Drawer>
  );
};

export default NewCustomerModal;
