import { Card, Checkbox, InputNumber, Select } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { DCTooltip } from '../../../../common';
import CardWrapper, { cardBorderCss } from '../../Common/CardWrapper';
import { useTranslation } from 'react-i18next';

export const DataIntegrationCard = ({
  value,
  onValueChange,
}: {
  value: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();
  return (
    <CardWrapper
      alternative
      title={t('Data Integration')}
      description={t('Specify how Digicust uses the information.')}
      checked={value?.dataIntegration?.active}
      onChange={(e) =>
        onValueChange({
          ...value,
          dataIntegration: {
            ...(value.dataIntegration || {}),
            active: e,
          },
        })
      }
    >
      {value.dataIntegration?.active && (
        <div>
          <div className="hoverable">
            {t('Require user input')}
            <DCTooltip
              text={t(
                'Defines whether to wait for user input before sending the customs case to the next system',
              )}
            />
            <Select
              popupMatchSelectWidth={false}
              size="small"
              placeholder={t('Require User Input')}
              optionFilterProp="children"
              value={value.dataIntegration?.requireUserInput || 'false'}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataIntegration: {
                    ...(value.dataIntegration || {}),
                    requireUserInput: e,
                  },
                })
              }
            >
              <Select.Option value="false">{t('No Requirement')}</Select.Option>
              <Select.Option value="true">
                {t('Require User Input')}
              </Select.Option>
              <Select.Option value="masterDataOrUserInput">
                {t('Require Master Data or User Input')}
              </Select.Option>
            </Select>
          </div>
          <Checkbox
            className="hoverable"
            checked={
              value.dataIntegration?.createNewCustomsCaseForInvoice?.active
            }
            onChange={(e) =>
              onValueChange({
                ...value,
                dataIntegration: {
                  ...(value.dataIntegration || {}),
                  createNewCustomsCaseForInvoice: {
                    ...(value?.dataIntegration
                      ?.createNewCustomsCaseForInvoice || {}),
                    active: e.target.checked,
                  },
                },
              })
            }
          >
            {t('Create new case for every invoice')}
            <DCTooltip
              text={t('Will handle every invoice as its own customs case.')}
            />
          </Checkbox>
          <Checkbox
            className="hoverable"
            checked={
              value.dataIntegration?.createNewCustomsCasesAsMentionedInEmail
                ?.active
            }
            onChange={(e) =>
              onValueChange({
                ...value,
                dataIntegration: {
                  ...(value.dataIntegration || {}),
                  createNewCustomsCasesAsMentionedInEmail: {
                    ...(value?.dataIntegration
                      ?.createNewCustomsCasesAsMentionedInEmail || {}),
                    active: e.target.checked,
                  },
                },
              })
            }
          >
            {t('Create new case as mentioned in email')}
            <DCTooltip
              text={t(
                'If multiple shipments/declarations are requested in email, Digicust will split the case accordingly.',
              )}
            />
          </Checkbox>
          <Checkbox
            className="hoverable"
            checked={
              value.dataIntegration?.createNewCustomsCasesPerRecipient?.active
            }
            onChange={(e) =>
              onValueChange({
                ...value,
                dataIntegration: {
                  ...(value.dataIntegration || {}),
                  createNewCustomsCasesPerRecipient: {
                    ...(value?.dataIntegration
                      ?.createNewCustomsCasesPerRecipient || {}),
                    active: e.target.checked,
                  },
                },
              })
            }
          >
            {t('Create new case as per invoice recipient')}
            <DCTooltip
              text={t(
                'For every recipient found on invoices, Digicust creates a new customs case.',
              )}
            />
          </Checkbox>
          <Checkbox
            className="hoverable"
            checked={
              value.dataIntegration?.createNewCustomsCaseForEveryXItems?.active
            }
            onChange={(e) =>
              onValueChange({
                ...value,
                dataIntegration: {
                  ...(value.dataIntegration || {}),
                  createNewCustomsCaseForEveryXItems: {
                    ...(value?.dataIntegration
                      ?.createNewCustomsCaseForEveryXItems || {}),
                    active: e.target.checked,
                    threshold:
                      value?.dataIntegration?.createNewCustomsCaseForEveryXItems
                        ?.threshold || 99,
                  },
                },
              })
            }
          >
            {t('Create new case for every X items')}
            <DCTooltip
              text={t(
                'If your customs software has a limit on the number of items you can have Digicust create and submit a new case for every X items.',
              )}
            />
          </Checkbox>
          {value?.dataIntegration?.createNewCustomsCaseForEveryXItems
            ?.active && (
            <Card style={cardBorderCss}>
              <div className="hoverable" style={{ display: 'inline-block' }}>
                {t('Threshold')}
                <DCTooltip
                  text={t(
                    'Threshold at which the case is cut into two (or more).',
                  )}
                />
                <InputNumber
                  size="small"
                  value={
                    value?.dataIntegration?.createNewCustomsCaseForEveryXItems
                      ?.threshold
                  }
                  onChange={(e) =>
                    onValueChange({
                      ...value,
                      dataIntegration: {
                        ...(value.dataIntegration || {}),
                        createNewCustomsCaseForEveryXItems: {
                          ...(value?.dataIntegration
                            ?.createNewCustomsCaseForEveryXItems || {}),
                          threshold: e || undefined,
                        },
                      },
                    })
                  }
                />
              </div>
              <Checkbox
                className="hoverable"
                checked={
                  value.dataIntegration?.createNewCustomsCaseForEveryXItems
                    ?.aggregated
                }
                onChange={(e) =>
                  onValueChange({
                    ...value,
                    dataIntegration: {
                      ...(value.dataIntegration || {}),
                      createNewCustomsCaseForEveryXItems: {
                        ...(value?.dataIntegration
                          ?.createNewCustomsCaseForEveryXItems || {}),
                        aggregated: e.target.checked,
                      },
                    },
                  })
                }
              >
                {t('Split on aggregated items')}
                <DCTooltip
                  text={t(
                    'Takes into account aggregation by tariff number and country of origin when splitting the case.',
                  )}
                />
              </Checkbox>
            </Card>
          )}
        </div>
      )}
    </CardWrapper>
  );
};
