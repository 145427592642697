import {
  combineReducers,
  configureStore,
  createAction,
} from '@reduxjs/toolkit';
import caseSlice, { CaseReduxState, initialCaseState } from './caseSlice';
import customerProjectSlice, {
  CustomerProjectReduxState,
} from './customerProjectSlice';
import materialSlice, {
  MaterialReduxState,
  initialMaterialState,
} from './masterMaterialSlice';
import stakeholderSlice, {
  StakeholderReduxState,
  initialStakeholderState,
} from './masterStakeholderSlice';
import statisticsSlice, {
  StatisticsReduxState,
  initialStatisticsState,
} from './statisticsSlice';

export type RootState = {
  caseSlices: CaseReduxState;
  material: MaterialReduxState;
  stakeholder: StakeholderReduxState;
  customerProjectSlice: CustomerProjectReduxState;
  statisticsSlice: StatisticsReduxState;
};

const rootReducer = combineReducers<RootState>({
  caseSlices: caseSlice,
  material: materialSlice,
  stakeholder: stakeholderSlice,
  customerProjectSlice: customerProjectSlice,
  statisticsSlice: statisticsSlice,
});

// Action to reset the state
export const resetState = createAction('resetState');

// Root reducer with reset state functionality
const rootReducerWithReset = (state: any, action: any) => {
  if (action.type === resetState.type) {
    const { customerProjectSlice } = state;

    return rootReducer(
      {
        caseSlices: initialCaseState,
        material: initialMaterialState, // Reset material slice
        stakeholder: initialStakeholderState, // Reset stakeholder slice
        customerProjectSlice,
        statisticsSlice: initialStatisticsState, // Reset statistics slice
      },
      action,
    );
  }
  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducerWithReset,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        ignoredPaths: ['some.nonSerializable.path', 'some.other.path'],
      },
    }),
});
