// Function to get original color based on hash
export function getColor(str: string) {
  const colors = [
    'magenta',
    'red',
    'volcano',
    'orange',
    'gold',
    'lime',
    'cyan',
  ];

  function hashCode(str: string) {
    let hash = 0;
    if (str.length === 0) {
      return hash;
    }
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }
    return hash;
  }

  const index = Math.abs(hashCode(str)) % colors.length;
  return colors[index];
}

// Function to lighten a color by a given percentage
export function lightenColor(colorName: string, percent: number) {
  // Map color names to RGB
  const colorMap: { [key: string]: [number, number, number] } = {
    magenta: [255, 0, 255],
    red: [255, 0, 0],
    volcano: [255, 99, 71], // approximated
    orange: [255, 165, 0],
    gold: [255, 215, 0],
    lime: [50, 205, 50], // approximated
    cyan: [0, 255, 255],
  };

  const rgb = colorMap[colorName];

  // Lighten color
  const lightenedRgb = rgb.map((c) =>
    Math.min(255, Math.floor(c + ((255 - c) * percent) / 100)),
  ) as [number, number, number];

  // Convert to CSS string
  return `rgb(${lightenedRgb[0]}, ${lightenedRgb[1]}, ${lightenedRgb[2]})`;
}
