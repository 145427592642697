/**
 * getUniqueObjects
 * @param arr - Array of objects
 * @param key - Can handle nested keys like "a.b.c"
 * @returns
 */
export default function getUniqueObjects(arr: any[], key: string) {
  const uniqueIds = new Set();
  function getNestedValue(obj: any, key: string): any {
    return key
      .split('.')
      .reduce((o, k) => (o && o[k] !== undefined ? o[k] : undefined), obj);
  }
  const uniqueObjects = arr.filter((obj) => {
    const value = getNestedValue(obj, key);
    if (!uniqueIds.has(value)) {
      uniqueIds.add(value);
      return true;
    }
    return false;
  });
  return uniqueObjects;
}
