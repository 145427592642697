import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Seal } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import DGTable from '../../../common/DGTable';
import DebouncedInput from '../../../Components/Inputs/Common/DebouncedInput';

const SealsInput = ({
  value = [],
  onValueChange,
}: {
  value: Seal[];
  onValueChange: (value: Seal[]) => void;
}) => {
  const { t } = useTranslation();
  const updateItem = (document: Seal, index: number) => {
    if (typeof index === 'number') {
      const newValue = [...value];
      newValue[index] = document;
      onValueChange(newValue);
    }
  };

  return (
    <div>
      <DGTable<Seal>
        showHeader={false}
        headerTitle={t('Seals')}
        scroll={{ y: 'calc(100vh - 300px)' }}
        dataSource={value || []}
        rightToolbar={
          <Button
            onClick={() => onValueChange([...value, {}])}
            key="button"
            icon={<PlusOutlined />}
            tabIndex={2}
          >
            {t('Add')}
          </Button>
        }
        columns={[
          {
            getValue: (document) => document?.value || '',
            title: t('Seal'),
            width: '150px',
            render: (_, item, index) => (
              <DebouncedInput
                placeholder={t('Seal')}
                value={item?.value}
                onChange={(value) => {
                  updateItem({ ...item, input: value, value }, index);
                }}
              />
            ),
          },
          {
            title: 'Actions',
            key: 'actions',
            width: '30px',
            render: (_, item, index) => (
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => {
                  const newValue = [...value];
                  newValue.splice(index, 1);
                  onValueChange(newValue);
                }}
              ></Button>
            ),
          },
        ]}
      />
    </div>
  );
};

export default SealsInput;
