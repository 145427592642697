import { CheckCircleOutlined } from '@ant-design/icons';
import { Descriptions, Popover, Tag, Typography } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { EORI } from 'digicust_types';
import { useTranslation } from 'react-i18next';

export const EORINumberValidation = ({ eori }: { eori?: EORI }) => {
  const { t } = useTranslation();

  return (
    <Popover
      title={`${t('EORI Validation')} ${eori?.value}`}
      content={
        <Paragraph style={{ maxWidth: '400px' }}>
          <Descriptions column={1} size="small" bordered={true}>
            <Descriptions.Item label={t('Validity')}>
              <Tag color={eori?.valid ? 'success' : 'warning'}>
                {eori?.valid ? t('Valid') : t('Invalid')}
              </Tag>
            </Descriptions.Item>
            {eori?.validationInformation?.validationSource && (
              <Descriptions.Item label={t('Source')}>
                <Typography.Text
                  style={{ maxWidth: '180px' }}
                  title={eori?.validationInformation?.validationSource}
                >
                  {eori?.validationInformation?.validationSource}
                </Typography.Text>
              </Descriptions.Item>
            )}
            {eori?.validationInformation?.statusDescr && (
              <Descriptions.Item label={t('Status')}>
                <Typography.Text
                  style={{ maxWidth: '180px' }}
                  title={eori?.validationInformation?.statusDescr}
                >
                  {eori?.validationInformation?.statusDescr}
                </Typography.Text>
              </Descriptions.Item>
            )}
            {eori?.validationInformation?.name && (
              <Descriptions.Item label={t('Name')}>
                <Typography.Text
                  style={{ maxWidth: '180px' }}
                  title={eori?.validationInformation?.name}
                >
                  {eori?.validationInformation?.name}
                </Typography.Text>
              </Descriptions.Item>
            )}
            {eori?.validationInformation?.address && (
              <Descriptions.Item label={t('Address')}>
                <Typography.Text
                  style={{ maxWidth: '180px' }}
                  title={eori?.validationInformation?.address}
                >
                  {eori?.validationInformation?.address}
                </Typography.Text>
              </Descriptions.Item>
            )}
          </Descriptions>
        </Paragraph>
      }
    >
      {eori?.valid ? (
        <Tag style={{ margin: 0 }} color="success">
          <CheckCircleOutlined />
        </Tag>
      ) : eori?.valid === false ? (
        <Tag style={{ margin: 0 }} color="warning">
          {t('Invalid')}
        </Tag>
      ) : (
        ''
      )}
    </Popover>
  );
};
