import { Avatar, Select, theme } from 'antd';
import { SubmissionVendor } from 'digicust_types';
import { getCustomsSoftwareLogoFromString } from '../../../../utils/helpers/getCustomsSoftwareLogoFromString';
import { CheckCard } from '@ant-design/pro-components';

export default function CustomsSystemSelection({
  value,
  onChange,
}: {
  value?: SubmissionVendor;
  onChange: (value: SubmissionVendor) => void;
}) {
  const { useToken } = theme;

  const { token } = useToken();
  return (
    <>
      <CheckCard.Group
        onChange={onChange as any}
        value={value}
        size="small"
        defaultValue="A"
      >
        {[
          { value: SubmissionVendor.dakosy, label: 'Dakosy' },
          { value: SubmissionVendor.dbh, label: 'DBH' },
          { value: SubmissionVendor.aeb, label: 'AEB' },
          { value: SubmissionVendor.ldv, label: 'LDV' },
          { value: SubmissionVendor.dhf, label: 'DHF Zolaris' },
          { value: SubmissionVendor.format, label: 'FORMAT' },
          { value: SubmissionVendor.asycuda, label: 'Asycuda' },
          { value: SubmissionVendor.beo, label: 'BEO' },
          {
            value: SubmissionVendor.cargosoft,
            label: 'CargoSoft',
          },
          {
            value: SubmissionVendor.mercurio,
            label: 'Mercurio',
          },
          {
            value: SubmissionVendor.scope,
            label: 'Scope',
          },
        ]?.map(({ value, label }) => (
          <CheckCard
            style={
              {
                // background: token.colorBgElevated,
                // color: token.colorTextBase,
                // border: token.colorBgElevated,
              }
            }
            avatar={
              <Avatar
                shape="square"
                size="small"
                src={getCustomsSoftwareLogoFromString(value)}
              />
            }
            title={label}
            // description={disabled && ` (Coming soon)`}
            value={value}
          />
        ))}
      </CheckCard.Group>
    </>
  );
}
