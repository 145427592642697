import { Tour, TourStepProps } from 'antd';
import { useEffect, useState } from 'react';

interface FirstTimeTourProps {
  steps: TourStepProps[]; // The steps for the tour.
  tourId: string; // A unique identifier for the tour.
  open?: boolean; // Optional prop to control the openness from outside.
  placement?: any;
  onClose?: () => void; // Optional callback for when the tour is closed.
}

const FirstTimeTour = ({
  steps,
  tourId,
  placement,
  open: controlledOpen,
  onClose: controlledOnClose,
}: FirstTimeTourProps) => {
  const [internalOpen, setInternalOpen] = useState(false);
  const storageKey = `hasSeenTour:${tourId}`;

  useEffect(() => {
    if (!localStorage.getItem(storageKey)) {
      setInternalOpen(true);
    }
  }, [storageKey]);

  const isOpen = controlledOpen !== undefined ? controlledOpen : internalOpen;

  const handleClose = () => {
    if (controlledOnClose) {
      controlledOnClose();
    } else {
      localStorage.setItem(storageKey, 'true');
      setInternalOpen(false);
    }
  };

  return (
    <Tour
      open={isOpen}
      onClose={handleClose}
      steps={steps}
      placement={placement}
    />
  );
};

export default FirstTimeTour;
