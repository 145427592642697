import { notification as antdNotification } from 'antd';

export const notification = {
  info: (message: string, description?: string) => {
    antdNotification.info({
      message,
      description,
      placement: 'bottomLeft',
      duration: 3,
    });
  },
  success: (message: string, description?: string) => {
    antdNotification.success({
      message,
      description,
      placement: 'bottomLeft',
      duration: 3,
    });
  },
  error: (message: string, description?: string) => {
    antdNotification.error({
      message,
      description,
      placement: 'bottomLeft',
      duration: 3,
    });
  },
};
