import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Select, Space, Typography } from 'antd';
import {
  ATB,
  PreliminaryDocument,
  PreliminaryDocumentType,
  T1PreliminaryDocument,
} from 'digicust_types';
import { useTranslation } from 'react-i18next';
import DGTable from '../../../common/DGTable';
import DebouncedInput from '../../../Components/Inputs/Common/DebouncedInput';
import NumberInput from '../../../Components/Inputs/Common/NumberInput';

const PreliminaryDocuments = ({
  value = [],
  onValueChange,
}: {
  value: PreliminaryDocument[];
  onValueChange: (value: PreliminaryDocument[]) => void;
}) => {
  const { t } = useTranslation();

  const updateItem = (document: PreliminaryDocument, index: number) => {
    if (typeof index === 'number') {
      const newValue = [...value];
      newValue[index] = document;
      onValueChange(newValue);
    }
  };

  return (
    <DGTable<PreliminaryDocument>
      showHeader={false}
      headerTitle={t('Preliminary Documents')}
      scroll={{ y: 'calc(100vh - 300px)' }}
      dataSource={value || []}
      rightToolbar={
        <Button
          onClick={() =>
            onValueChange([...value, { type: PreliminaryDocumentType.ATB }])
          }
          key="button"
          icon={<PlusOutlined />}
        >
          {t('Add')}
        </Button>
      }
      columns={[
        {
          getValue: (document) => document?.type || '',
          title: t('Type'),
          key: 'type',
          fixed: 'left',
          width: '140px',
          render: (_, item, index) => (
            <Select
              variant="filled"
              key={index}
              style={{ width: '100%' }}
              showSearch
              popupMatchSelectWidth={200}
              placeholder={t('Select Type')}
              value={item?.type}
              onChange={(e) => {
                updateItem({ ...item, type: e }, index);
              }}
            >
              {[
                {
                  label: 'ATB',
                  value: PreliminaryDocumentType.ATB,
                },
                {
                  label: 'T1',
                  value: PreliminaryDocumentType.T1,
                },
              ]?.map((item, key) => (
                <Select.Option
                  title={item?.label}
                  key={key}
                  value={item?.value}
                  label={item?.label}
                >
                  <Space key={key}>
                    <Typography.Text
                      style={{ maxWidth: '400px', display: 'flex' }}
                      ellipsis
                    >
                      {item.label}
                    </Typography.Text>
                  </Space>
                </Select.Option>
              ))}
            </Select>
          ),
        },
        {
          getValue: (document) => (document as ATB)?.ATBNumber?.value || '',
          title: t('Number'),
          key: 'number',
          fixed: 'left',
          width: '130px',
          render: (_, item, index) => (
            <DebouncedInput
              placeholder={t('Number')}
              value={
                (item as ATB)?.ATBNumber?.value ||
                (item as T1PreliminaryDocument)?.reference?.value
              }
              onChange={(e) => {
                updateItem(
                  {
                    ...(item || {}),
                    ATBNumber: {
                      input: e,
                      value: e,
                    },
                    reference: {
                      input: e,
                      value: e,
                    },
                  } as ATB,
                  index,
                );
              }}
            />
          ),
        },
        {
          getValue: (document) =>
            (document as ATB)?.lineItem?.value?.toString() || '',
          title: t('Position'),
          key: 'position',
          width: '100px',
          render: (_, item, index) => (
            <NumberInput
              placeholder={t('Position')}
              value={(item as ATB)?.lineItem?.value}
              onChange={(e) =>
                updateItem(
                  {
                    ...(item || {}),
                    lineItem: { input: e?.toString(), value: e },
                  } as ATB,
                  index,
                )
              }
            />
          ),
        },
        {
          getValue: (document) =>
            (document as ATB)?.amountOfPackages?.value?.toString() || '',
          title: t('Amount Of Packages'),
          key: 'amountOfPackages',
          width: '100px',
          render: (_, item, index) => (
            <NumberInput
              placeholder={t('Amount Of Packages')}
              value={(item as ATB)?.amountOfPackages?.value}
              onChange={(e) =>
                updateItem(
                  {
                    ...(item || {}),
                    amountOfPackages: { input: e?.toString(), value: e },
                  } as ATB,
                  index,
                )
              }
            />
          ),
        },
        {
          getValue: (document) =>
            (document as ATB)?.grossWeight?.value?.toString() || '',
          title: t('Gross Weight'),
          key: 'grossWeight',
          width: '100px',
          render: (_, item, index) => (
            <NumberInput
              placeholder={t('Gross Weight')}
              value={(item as ATB)?.grossWeight?.value}
              onChange={(e) =>
                updateItem(
                  {
                    ...(item || {}),
                    grossWeight: { input: e?.toString(), value: e },
                  } as ATB,
                  index,
                )
              }
            />
          ),
        },
        {
          title: 'Actions',
          key: 'actions',
          width: '30px',
          render: (_, item, index) => (
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => {
                const newValue = [...value];
                newValue.splice(index, 1);
                onValueChange(newValue);
              }}
            ></Button>
          ),
        },
      ]}
    />
  );
};

export default PreliminaryDocuments;
