import { CaseModel, FilterCondition, ProcedureMode } from 'digicust_types';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { CaseRepository } from '../../../utils/repositories/case.repository';

type CCgetListResponse = {
  result: CaseModel[];
  maxPages?: number;
  totalCount?: number;
};

type CaseLoadDataProps = {
  procedureMode: ProcedureMode | 'all';
  pageNumber?: number;
  pageSize?: number;
};

type CCCtype = {
  loadData: (obj: CaseLoadDataProps) => Promise<void>;
  dateFilter?: DateFilter;
  setDateFilter: Dispatch<SetStateAction<DateFilter | undefined>>;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  casesData: CCgetListResponse;
  customerId: string;
  projectId: string;
  menuItem?: string;
  loading: boolean;
};

const CustomsCasesContext = React.createContext<CCCtype>({} as CCCtype);

export function useCustomsCasesContext() {
  return useContext(CustomsCasesContext);
}

type DateFilter = {
  startDate: Date;
  endDate: Date;
};

export default function CustomsCaseProvider({
  children,
  customerId,
  projectId,
}: {
  children: any;
  customerId: string;
  projectId: string;
}) {
  const [dateFilter, setDateFilter] = useState<DateFilter | undefined>(
    undefined,
  );
  const [search, setSearch] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [casesData, setCasesData] = useState<CCgetListResponse>({ result: [] });

  const loadData = async ({
    procedureMode = 'all',
    pageNumber = 1,
    pageSize = 10,
  }: CaseLoadDataProps) => {
    setLoading(true);

    const filter = [
      ...(procedureMode === 'all'
        ? []
        : [
            {
              field: 'procedureMode',
              condition: FilterCondition.equals,
              value: procedureMode,
            },
          ]),
      ...(dateFilter?.startDate && dateFilter?.endDate
        ? [
            {
              field: 'createdAt',
              condition: FilterCondition.greaterThan,
              value: dateFilter?.startDate,
            },
            {
              field: 'createdAt',
              condition: FilterCondition.smallerThan,
              value: dateFilter?.endDate,
            },
          ]
        : []),
    ];

    const response: CCgetListResponse = await CaseRepository.getList(
      customerId,
      projectId,
      {
        filter,
        search: [search],
        pageLimit: pageSize,
        sort: [
          {
            field: 'createdAt',
            order: 'DESC',
          },
        ],
        pageNumber,
      },
    );

    const caseListData = response?.result?.map((res: CaseModel) => ({
      ...res,
      key: res.id,
    }));

    setCasesData({ ...response, result: caseListData });

    setLoading(false);
  };

  const context: CCCtype = useMemo(
    () => ({
      loadData,
      dateFilter,
      setDateFilter,
      search,
      setSearch,
      loading,
      casesData,
      customerId,
      projectId,
      menuItem: 'all',
    }),
    [dateFilter, search, loading, casesData, customerId, projectId],
  );

  return (
    <CustomsCasesContext.Provider value={context}>
      {children}
    </CustomsCasesContext.Provider>
  );
}
