const config = {
  msal: {
    auth: {
      clientId: 'c41cebcc-2a6c-4e60-a7d4-3e37c4f19ffb',
      authority:
        'https://digicustcustomers.b2clogin.com/tfp/digicustcustomers.onmicrosoft.com/B2C_1_digicust_signup/',
      knownAuthorities: ['digicustcustomers.b2clogin.com'],
      redirectUri: `${window.origin}/`,
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  msalRequest: {
    scopes: ['openid c41cebcc-2a6c-4e60-a7d4-3e37c4f19ffb offline_access'],
  },
  API_URL: 'https://dev.api.digicust.com',
  // API_URL: 'http://localhost:3005',
};

export default config;
