import {
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleTwoTone,
  InfoCircleTwoTone,
  WarningTwoTone,
} from '@ant-design/icons';
import { Button, Card, Form, Input, List, message, Select, Space } from 'antd';
import { ValidationError } from 'digicust_types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const severityOptions = [
  { value: 'error', label: 'Error', color: 'red' },
  { value: 'warning', label: 'Warning', color: 'orange' },
  { value: 'info', label: 'Info', color: 'blue' },
];

interface CustomValidationMessagesComponentProps {
  value: ValidationError[];
  onValueChange: (value: ValidationError[]) => void;
}

export const SeverityIcon = (props: { severity?: string }) => {
  switch (props?.severity) {
    case 'error':
      return <ExclamationCircleTwoTone twoToneColor="red" />;
    case 'warning':
      return <WarningTwoTone twoToneColor="orange" />;
    case 'info':
      return <InfoCircleTwoTone twoToneColor="blue" />;
    case 'success':
      return <CheckOutlined style={{ color: 'green' }} />;
    default:
      return <ExclamationCircleTwoTone twoToneColor="red" />;
  }
};

const CustomValidationMessagesComponent: React.FC<
  CustomValidationMessagesComponentProps
> = ({ value, onValueChange }) => {
  const { t } = useTranslation();
  const [newMessage, setNewMessage] = useState('');
  const [newSeverity, setNewSeverity] = useState(severityOptions[0].value);

  const handleAddMessage = () => {
    if (!newSeverity || !newMessage) {
      message.error(t('Values are required'));
      return;
    }
    onValueChange([
      ...value,
      {
        name: newMessage,
        message: newMessage,
        type: 'ValidationError',
        severity: newSeverity as 'error' | 'warning' | 'info',
      },
    ]);
    setNewMessage('');
    setNewSeverity(severityOptions[0].value);
  };

  const handleDeleteMessage = (index: number) => {
    const newValue = [...value];
    newValue.splice(index, 1);
    onValueChange(newValue);
  };

  return (
    <div>
      <Card title={t('Add Validation Message')}>
        <Form layout="vertical">
          <Space>
            <Form.Item label={t('Severity')} required>
              <Select
                style={{ width: '150px' }}
                value={newSeverity}
                onChange={(value) => setNewSeverity(value)}
              >
                {severityOptions.map((severity, key) => (
                  <Option key={key} value={severity.value}>
                    {severity.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label={t('New validation error message')} required>
              <Input
                placeholder={t('Custom validation message')}
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              />
            </Form.Item>

            <Button type="primary" onClick={handleAddMessage}>
              {t('Add Message')}
            </Button>
          </Space>
        </Form>

        <List
          itemLayout="horizontal"
          dataSource={value}
          renderItem={(item: ValidationError, index: number) => (
            <List.Item
              actions={[
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => handleDeleteMessage(index)}
                  danger
                >
                  {t('Delete')}
                </Button>,
              ]}
            >
              <List.Item.Meta
                avatar={SeverityIcon({ severity: item.severity })}
                title={item.message}
              />
            </List.Item>
          )}
        />
      </Card>
    </div>
  );
};

export default CustomValidationMessagesComponent;
