import { Button, Card, Input, Space, Spin, Typography, message } from 'antd';
import { TariffNumberSuggestion } from 'digicust_types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TariffClassificationService } from '../../utils/services/tariff-classification.service';
import { useAppDataContext } from '../AppDataProvider';
import { LoadingOutlined } from '@ant-design/icons';

export default function TariffClassifierCard() {
  const { customerId, projectId, projectDetails } = useAppDataContext();
  const { t } = useTranslation();

  const [description, setDescription] = useState<string>();
  const [remark, setRemark] = useState<string>();
  const [barCode, setBarCode] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const [CTNSuggestion, setCTNSuggestions] =
    useState<TariffNumberSuggestion | null>();

  const handleSubmit = async () => {
    setCTNSuggestions(null);
    setLoading(true);

    if (!description) {
      message.error('Description is required for classification');
      return;
    }

    try {
      const suggestion = await TariffClassificationService.classify({
        customerId: customerId!,
        projectId: projectId!,
        description,
        remark,
        tariffSystem: projectDetails?.tariffNumberTreeSystem,
        barCode,
      });

      if (suggestion) {
        setCTNSuggestions(suggestion);

        message.success(
          `Classification successful. Tariff number is ${suggestion.tariffNumber}`,
        );
      } else {
        message.error('No suggestions found.');
      }
    } catch (error: any) {
      message.error(error.response.data || 'Classification failed.');
    }
    setLoading(false);
  };

  const getTariffSuggestion = useMemo(() => {
    return [
      "Women's leather upper high-heeled shoes, size 7",
      'Stainless steel kitchen knife set, 5 pieces',
      "100% cotton men's t-shirt, blue, large",
      'Frozen raw Atlantic salmon fillets, 1 kg package',
      'Aluminum alloy bicycle frame for mountain bikes',
      "Children's plastic toy action figure, approximately 6 inches tall",
      'Laptop computer with a 15-inch screen and 256GB SSD',
      'Olive oil, extra virgin, in a 500ml glass bottle',
      'Handcrafted clay flower pot, 12 inches in diameter',
      'Wooden dining table, oak, seats 6',
      'Electric hair dryer, 2000W, with two speed settings',
      'Natural rubber yoga mat, purple, 5mm thick',
      'Leather-bound notebook with 100 blank pages',
      'Quartz wristwatch with stainless steel strap',
      'Dog food, chicken flavor, dry, 5kg bag',
      'Acrylic paint set, 12 colors, 10ml tubes',
      'Porcelain tea cup and saucer set, white with gold trim',
      "Men's polyester swimming trunks, red and white striped, size M",
      'Plastic garden hose nozzle with adjustable spray settings',
      'Compact digital camera with 12MP resolution and 4x optical zoom',
      'Crystal chandelier with 5 arms and LED bulbs',
      'Vinyl LP record of classic rock hits from the 70s',
      'Ceramic brake pads for passenger cars, set of 4',
      'Glass perfume bottle, 50ml, with floral scent',
      'Mixed nuts (almonds, walnuts, cashews), salted, in a 200g tin',
    ]
      .sort(() => 0.5 - Math.random())
      .slice(0, 3);
  }, []);

  return (
    <Card style={{ width: '100%' }}>
      <Space direction="vertical" size="middle">
        <Typography.Title level={5} data-testId="tariff-classification-module">
          {t('Tariff Classification')}
        </Typography.Title>

        <Space wrap>
          <Input
            data-testId="tariff-classification-input"
            style={{ width: '400px' }}
            value={description}
            allowClear
            placeholder={t('Enter goods description')}
            onChange={(e) => setDescription(e.target.value)}
          />

          <Input
            style={{ width: '200px' }}
            value={remark}
            allowClear
            placeholder={t('Remarks (Optional)')}
            onChange={(e) => setRemark(e.target.value)}
          />
          <Input
            style={{ width: '200px' }}
            value={barCode}
            allowClear
            placeholder={t('Barcode (Optional)')}
            onChange={(e) => setBarCode(e.target.value)}
          />
          <Button
            data-testId="tariff-classification-submit"
            htmlType="submit"
            loading={loading}
            disabled={!description || loading}
            type="primary"
            onClick={() => handleSubmit()}
          >
            {t('Submit')}
          </Button>

          {CTNSuggestion && (
            <Button
              onClick={() => {
                setCTNSuggestions(null);
                setDescription('');
                setRemark('');
              }}
            >
              {t('Clear')}
            </Button>
          )}
        </Space>

        {!CTNSuggestion &&
          (!loading ? (
            <Space size={0} wrap>
              <Typography.Text type="secondary">
                {t('Examples')}:
              </Typography.Text>

              {getTariffSuggestion.map((example) => (
                <Button
                  key={example}
                  type="link"
                  onClick={() => setDescription(example)}
                >
                  {example}
                </Button>
              ))}
            </Space>
          ) : (
            <Space>
              <Spin indicator={<LoadingOutlined spin />} size="small" />
              <Typography.Text type="secondary">
                {t('Classifying')}{' '}
                <Typography.Text type="secondary" strong>
                  {description}
                </Typography.Text>
                . {t('This can take up to 60 seconds.')}
              </Typography.Text>
            </Space>
          ))}

        {CTNSuggestion && (
          <Card>
            <Space direction="vertical" size={0} style={{ fontSize: 10 }}>
              <Typography.Text
                strong
                copyable={{ text: CTNSuggestion.tariffNumber }}
              >
                {t('Tariff Number')}: {CTNSuggestion.tariffNumber}
              </Typography.Text>
              {CTNSuggestion.reason && (
                <Typography.Text italic>
                  {t('Reason')}: {CTNSuggestion.reason}
                </Typography.Text>
              )}
            </Space>
          </Card>
        )}
      </Space>
    </Card>
  );
}
