import { LineItemModel } from 'digicust_types';

function aggregateProperty(acc: any, item: any, key: string, propName: string) {
  if (!acc[key]) {
    acc[key] = { ...item };
  } else {
    // Ensure the property container exists
    if (!acc[key][propName]) {
      acc[key][propName] = { ...item[propName] };
    } else {
      // Handle 'amount' or 'value' if present
      if (typeof item[propName]?.amount === 'number') {
        acc[key][propName].amount =
          (acc[key][propName].amount || 0) + item[propName].amount;
      } else if (typeof item[propName]?.value === 'number') {
        acc[key][propName].value =
          (acc[key][propName].value || 0) + item[propName].value;
      } else if (typeof item[propName]?.value === 'string') {
        if (!acc[key][propName].value?.includes(item[propName].value)) {
          acc[key][propName].value = [
            acc[key][propName].value,
            item[propName].value,
          ]
            ?.filter((e) => e)
            ?.join(', ');
        }
      }
    }
  }
}

export const aggregateLineItems = (lineItems: LineItemModel[]) => {
  const aggregatedItems = lineItems.reduce(
    (acc, item) => {
      const key = `${item?.customsTariffNumber?.value}-${item?.countryOfOrigin?.alpha2Code}`;

      aggregateProperty(acc, item, key, 'quantity');
      aggregateProperty(acc, item, key, 'totalGrossWeight');
      aggregateProperty(acc, item, key, 'totalNetWeight');
      aggregateProperty(acc, item, key, 'itemSpecialUnitMeasure');
      aggregateProperty(acc, item, key, 'customsDuty');
      aggregateProperty(acc, item, key, 'guaranteeValue');
      aggregateProperty(acc, item, key, 'statisticalValue');

      aggregateProperty(acc, item, key, 'description');
      aggregateProperty(acc, item, key, 'code');
      aggregateProperty(acc, item, key, 'materialNumber');
      aggregateProperty(acc, item, key, 'buyerArticleNumber');
      aggregateProperty(acc, item, key, 'buyerOrderNumber');
      aggregateProperty(acc, item, key, 'reference');

      return acc;
    },
    {} as { [key: string]: LineItemModel },
  );

  return Object.values(aggregatedItems);
};
