import { Button, Modal } from 'antd';
import { Rule } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import RuleCatalogContent from './RuleCatalogContent';

const RuleCatalogModal = ({
  open,
  onOpenChange,
  onUseRule,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onUseRule: (rule: Rule) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      width={1000}
      title={t('Rules Catalog')}
      open={open}
      onCancel={() => onOpenChange(false)}
      footer={
        <Button onClick={() => onOpenChange(false)}>{t('Cancel')}</Button>
      }
    >
      <RuleCatalogContent onUseRule={onUseRule} />
    </Modal>
  );
};

export default RuleCatalogModal;
