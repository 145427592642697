import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

export default function DCTooltip({ text }: { text: string }) {
  return (
    <Tooltip title={text}>
      <QuestionCircleOutlined style={{ color: '#00000073', margin: '4px' }} />
    </Tooltip>
  );
}
