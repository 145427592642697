import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Select, Space, Typography } from 'antd';
import { Incoterm, MeanOfTransportationMode, Rule } from 'digicust_types';
import { t } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DebouncedInput from '../../../../../Components/Inputs/Common/DebouncedInput';
import NumberInput from '../../../../../Components/Inputs/Common/NumberInput';
import CountrySelect from '../../../../../Components/Inputs/CountrySelect';
import IncotermSelect from '../../../../../Components/Inputs/IncotermSelect';
import MeanOfTransportationModeSelect from '../../../../../Components/Inputs/MeanOfTransportationModeSelect';
import PackageTypeSelect from '../../../../../Components/Inputs/PackageTypeSelect';
import PortSelect from '../../../../../Components/Inputs/PortSelect';
import StakeholderSelect from '../../../../../Components/Inputs/StakeholderSelect';
import CustomsOfficeSelect from '../../../../CustomsCaseDetails/general-tab/CustomsOfficeSelect';

const getConditionOptions = (conditionType: string) => {
  switch (conditionType) {
    case 'number':
      return [
        {
          value: 'equals',
          label: t('Equal To'),
        },
        {
          value: 'greaterThan',
          label: t('Greater Than'),
        },
        {
          value: 'smallerThan',
          label: t('Smaller'),
        },
        {
          value: 'isNot',
          label: t('Is Not'),
        },
      ];
    case 'array':
      return [
        {
          value: 'array_contains',
          label: t('Contains'),
        },
        {
          value: 'array_includes',
          label: t('Includes'),
        },
        {
          value: 'array_length_bigger',
          label: t('Count bigger than'),
        },
        {
          value: 'array_length_smaller',
          label: t('Count smaller than'),
        },
      ];
    case '':
    case 'string':
      return [
        {
          value: 'contains',
          label: t('Contains'),
        },
        {
          value: 'notContains',
          label: t('Does NOT Contain'),
        },
        {
          value: 'startsWith',
          label: t('Starts With'),
        },
        {
          value: 'endsWith',
          label: t('Ends With'),
        },
        {
          value: 'equals',
          label: t('Equal To'),
        },
        {
          value: 'isNot',
          label: t('Is Not'),
        },
      ];
    default:
      return [
        {
          value: 'equals',
          label: t('Is'),
        },
        {
          value: 'isNot',
          label: t('Is Not'),
        },
      ];
  }
};

export default function ConditionBlock({
  value,
  onChange,
}: {
  value: Rule;
  onChange: (value: Rule) => void;
}) {
  const { t } = useTranslation();

  const updateCondition = (condition: any, index: number) => {
    if (typeof index === 'number') {
      const newValue = [...(value.conditions || [])];
      newValue[index] = condition;
      onChange({ ...value, conditions: newValue });
    }
  };

  const [searchValue, setSearchValue] = useState<string>('');

  const fieldList = [
    {
      label: t('Reference'),
      value: 'reference',
      type: 'string',
    },
    {
      label: t('Email Sender'),
      value: `documents[documentType="email"][0].from`,
      type: 'string',
    },
    {
      label: t('Email Subject'),
      value: `documents[documentType="email"][0].subject`,
      type: 'string',
    },
    {
      label: t('Email Body'),
      value: `documents[documentType="email"][0].body`,
      type: 'string',
    },
    {
      label: t('MRN Number'),
      value: 'aggregated.mrnNumber.value',
      type: 'string',
    },
    {
      label: t('Procedure'),
      value: 'aggregated.procedure.value',
      type: 'string',
    },
    {
      label: t('Procedure Code'),
      value: 'aggregated.procedure.procedureCodeCombination',
      type: 'string',
    },
    {
      label: t('Representation'),
      value: 'aggregated.constellationOfParticipants.value',
      type: 'string',
    },
    {
      label: t('Type of Business'),
      value: 'aggregated.typeofBusiness.value',
      type: 'string',
    },
    {
      label: t('Trade Preference Valid'),
      value: 'aggregated.tradePreference.valid',
      type: 'boolean',
    },
    {
      label: t('Trade Preference Text'),
      value: 'aggregated.tradePreference.value',
      type: 'string',
    },
    {
      label: t('Total Value'),
      value: 'aggregated.totalValue.value',
      type: 'number',
    },
    {
      label: t('Total Value Currency'),
      value: 'aggregated.totalValue.unit',
      type: 'currency',
    },
    {
      label: t('Exit Customs Office'),
      value: "aggregated.customsOffices[officeType = 'Exit'][0].code.value",
      type: 'customsOffice',
    },
    {
      label: t('Destination Customs Office'),
      value:
        "aggregated.customsOffices[officeType = 'Destination'][0].code.value",
      type: 'customsOffice',
    },
    {
      label: t('Export Customs Office'),
      value: "aggregated.customsOffices[officeType = 'Export'][0].code.value",
      type: 'customsOffice',
    },
    {
      label: t('Declaration Customs Office'),
      value:
        "aggregated.customsOffices[officeType = 'Declaration'][0].code.value",
      type: 'customsOffice',
    },
    {
      label: t('Entry Customs Office'),
      value: "aggregated.customsOffices[officeType = 'Entry'][0].code.value",
      type: 'customsOffice',
    },
    {
      label: t('Import Customs Office'),
      value: "aggregated.customsOffices[officeType = 'Import'][0].code.value",
      type: 'customsOffice',
    },
    {
      label: t('Departure Customs Office'),
      value:
        "aggregated.customsOffices[officeType = 'Departure'][0].code.value",
      type: 'customsOffice',
    },
    {
      label: t('Departure Address'),
      value: 'aggregated.fromAddress.formattedAddress',
      type: 'string',
    },
    {
      label: t('Departure Country'),
      value: 'aggregated.fromAddress.alpha2Code',
      type: 'country',
    },
    {
      label: t('Client Identifier'),
      value:
        'aggregated.importer.clientIdentifier.value ? aggregated.importer.clientIdentifier.value : (aggregated.exporter.clientIdentifier.value ? aggregated.exporter.clientIdentifier.value : "00101")',
      type: 'string',
    },
    {
      label: t('Destination Address'),
      value: 'aggregated.toAddress.formattedAddress',
      type: 'string',
    },
    {
      label: t('Destination Country'),
      value: 'aggregated.toAddress.alpha2Code',
      type: 'country',
    },
    {
      label: t('Place of Loading'),
      value: 'aggregated.placeOfLoading.formattedAddress',
      type: 'string',
    },
    {
      label: t('Point of Entry'),
      value: 'aggregated.pointOfEntry.formattedAddress',
      type: 'string',
    },
    {
      label: t('Point of Exit'),
      value: 'aggregated.pointOfExit.formattedAddress',
      type: 'string',
    },
    {
      label: t('Incoterm'),
      value: 'aggregated.incoterm.value',
      type: 'incoterm',
    },
    {
      label: t('Incoterm Place'),
      value: 'aggregated.placeIncoterm.formattedAddress',
      type: 'string',
    },
    {
      label: t('Port of Import'),
      value: 'aggregated.portOfImport.value',
      type: 'port',
    },
    {
      label: t('Port of Export'),
      value: 'aggregated.portOfExport.value',
      type: 'port',
    },
    {
      label: t('Freight Costs'),
      value: 'aggregated.freightCosts.value',
      type: 'number',
    },
    {
      label: t('Domestic Costs'),
      value: 'aggregated.domesticFreightCosts.value',
      type: 'number',
    },
    {
      label: t('Total Gross Weight'),
      value: 'aggregated.weight.value',
      type: 'number',
    },
    {
      label: t('Total Net Weight'),
      value: 'aggregated.netWeight.value',
      type: 'number',
    },
    {
      label: t('Number of Packages'),
      value: 'aggregated.numberOfShipments.value',
      type: 'number',
    },
    {
      label: t('Package Type'),
      value: '(aggregated.packages.type.code)[0]',
      type: 'packageType',
    },
    {
      label: t('Mean of Transportation at Border (Shipping Type)'),
      value: `aggregated.meansOfTransportation[type="Border"].value`,
      type: 'meanOfTransportationMode',
    },
    {
      label: t('Mean of Transportation at Border'),
      value: `aggregated.meansOfTransportation[type="Border"].licensePlate`,
      type: 'string',
    },
    {
      label: t('Line Item Count'),
      value: `$count(aggregated.items)`,
      type: 'number',
    },
    {
      label: t('Line Item Value Sum'),
      value: `$sum(aggregated.items.totalValue.value)`,
      type: 'number',
    },
    {
      label: t('Line Item Gross Weight Sum'),
      value: `$sum(aggregated.items.totalGrossWeight.value)`,
      type: 'number',
    },
    {
      label: t('Line Item Net Weight Sum'),
      value: `$sum(aggregated.items.totalNetWeight.value)`,
      type: 'number',
    },
    {
      label: t('Document Types'),
      value: `documents.documentType`,
      type: 'array',
    },
    {
      label: t('Document References'),
      value: `documents.reference`,
      type: 'array',
    },
    {
      label: t('Document Count'),
      value: `$count(documents)`,
      type: 'number',
    },
    ...[
      {
        label: t('Shipper'),
        value: 'aggregated.shipper',
      },
      {
        label: t('Waybill Shipper'),
        value: `documents[documentType="waybill"][0].shipper`,
      },
      {
        label: t('Consignee'),
        value: 'aggregated.consignee',
      },
      {
        label: t('Buyer'),
        value: 'aggregated.buyer',
      },
      {
        label: t('Declarant'),
        value: 'aggregated.declarant',
      },
      {
        label: t('Declarant Representative'),
        value: 'aggregated.declarantRepresentative',
      },
    ]
      .map((stakeholder) => [
        {
          label: t(stakeholder.label),
          value: `${stakeholder.value}.stakeholderId`,
          type: 'stakeholder',
        },
        {
          label: t(`${stakeholder.label} Name`),
          value: `${stakeholder.value}.name.value`,
          type: 'string',
        },
        {
          label: t(`${stakeholder.label} Country`),
          value: `${stakeholder.value}.address.alpha2Code`,
          type: 'country',
        },
        {
          label: t(`${stakeholder.label} Address`),
          value: `${stakeholder.value}.address.formattedAddress`,
          type: 'string',
        },
        {
          label: t(`${stakeholder.label} VAT Number`),
          value: `${stakeholder.value}.VAT.value`,
          type: 'string',
        },
        {
          label: t(`${stakeholder.label} EORI Number`),
          value: `${stakeholder.value}.EORI.value`,
          type: 'string',
        },
      ])
      .flat(),
  ];

  return (
    <Card
      style={
        {
          // borderLeft: '5px #1677ff solid',
          // minWidth: 'min-content',
        }
      }
    >
      <Space direction="vertical">
        <Space>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {t('Condition')}
          </Typography.Title>
        </Space>

        {value?.conditions?.map((condition, i) => {
          const conditionType =
            fieldList?.find((field) => field.value === condition.field)?.type ||
            '';
          return (
            <Space>
              <Select
                variant="filled"
                value={condition.field}
                popupMatchSelectWidth={400}
                showSearch
                onSearch={(e) => setSearchValue(e)}
                style={{ width: '200px' }}
                placeholder="Field"
                optionFilterProp="children"
                onChange={(e) =>
                  updateCondition(
                    {
                      ...condition,
                      field: e,
                      operator:
                        fieldList?.find((field) => field?.value === e)?.type ===
                        'string'
                          ? 'contains'
                          : 'equals',
                    },
                    i,
                  )
                }
                filterOption={(input, option) =>
                  `${option?.search} ${option?.value}`
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  `${optionA?.search}`
                    .toLowerCase()
                    .localeCompare(`${optionB?.search}`.toLowerCase())
                }
                options={[
                  ...((searchValue?.length || 0) > 0
                    ? [
                        {
                          search: searchValue,
                          value: searchValue,
                          type: 'string',
                          label: (
                            <Space direction="vertical" size={0}>
                              <Typography.Text>{searchValue}</Typography.Text>
                              <Typography.Text ellipsis type="secondary">
                                {t('New JSONata Expression')}
                              </Typography.Text>
                            </Space>
                          ),
                        },
                      ]
                    : []),
                  ...(fieldList?.map((option) => ({
                    ...option,
                    search: option.label,
                    label: (
                      <Space direction="vertical" size={0}>
                        <Typography.Text>{option.label}</Typography.Text>
                        <Typography.Text ellipsis type="secondary">
                          {option.value}
                        </Typography.Text>
                      </Space>
                    ),
                  })) || []),
                ]}
              />
              {['number', 'string', 'array', ''].includes(
                conditionType?.toString(),
              ) ? (
                <Select
                  popupMatchSelectWidth={false}
                  variant="filled"
                  value={condition.operator}
                  showSearch
                  onChange={(e) =>
                    updateCondition({ ...condition, operator: e }, i)
                  }
                  style={{ width: '120px' }}
                  placeholder="Operator"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '')?.toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      ?.toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? '')?.toString().toLowerCase(),
                      )
                  }
                  options={getConditionOptions(conditionType)}
                />
              ) : (
                <Select
                  variant="filled"
                  value={condition.operator}
                  showSearch
                  onChange={(e) =>
                    updateCondition({ ...condition, operator: e }, i)
                  }
                  style={{ width: '120px' }}
                  placeholder="Operator"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '')?.toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      ?.toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? '')?.toString().toLowerCase(),
                      )
                  }
                  options={getConditionOptions(conditionType)}
                />
              )}

              {conditionType === 'number' ||
              (conditionType === 'array' &&
                ['array_length_smaller', 'array_length_bigger'].includes(
                  condition?.operator || '',
                )) ? (
                <div style={{ width: '200px' }}>
                  <NumberInput
                    value={Number(condition.value)}
                    placeholder="Value"
                    onChange={(value) =>
                      updateCondition({ ...condition, value }, i)
                    }
                  />
                </div>
              ) : conditionType === 'string' || conditionType === '' ? (
                <div style={{ width: '200px' }}>
                  <DebouncedInput
                    value={condition.value}
                    placeholder="Value"
                    onChange={(value) =>
                      updateCondition({ ...condition, value }, i)
                    }
                  />
                </div>
              ) : conditionType === 'array' ? (
                <div style={{ width: '200px' }}>
                  <DebouncedInput
                    value={condition.value}
                    placeholder="Value"
                    onChange={(value) =>
                      updateCondition({ ...condition, value }, i)
                    }
                  />
                </div>
              ) : conditionType === 'packageType' ? (
                <PackageTypeSelect
                  value={{ code: condition.value }}
                  onChange={(e) =>
                    updateCondition({ ...condition, value: e?.code }, i)
                  }
                />
              ) : conditionType === 'stakeholder' ? (
                <div style={{ width: '200px' }}>
                  <StakeholderSelect
                    value={{ id: condition.value || '' }}
                    onChange={(e) =>
                      updateCondition({ ...condition, value: e?.id || '' }, i)
                    }
                  />
                </div>
              ) : conditionType === 'customsOffice' ? (
                <div style={{ width: '200px' }}>
                  <CustomsOfficeSelect
                    value={{
                      value: condition.value || '',
                      code: { value: condition.value || '' },
                    }}
                    onChange={(e) =>
                      updateCondition(
                        { ...condition, value: e?.value || '' },
                        i,
                      )
                    }
                  />
                </div>
              ) : conditionType === 'incoterm' ? (
                <IncotermSelect
                  value={{ value: condition.value as Incoterm }}
                  onChange={(e) =>
                    updateCondition({ ...condition, value: e?.value || '' }, i)
                  }
                />
              ) : conditionType === 'meanOfTransportationMode' ? (
                <MeanOfTransportationModeSelect
                  value={{
                    value: condition.value as MeanOfTransportationMode,
                  }}
                  onChange={(e) =>
                    updateCondition({ ...condition, value: e?.value || '' }, i)
                  }
                />
              ) : conditionType === 'port' ? (
                <PortSelect
                  value={{ value: condition.value }}
                  onChange={(e) =>
                    updateCondition({ ...condition, value: e?.value || '' }, i)
                  }
                />
              ) : conditionType === 'country' ? (
                <CountrySelect
                  value={{ alpha2Code: condition?.value }}
                  onChange={(e) =>
                    updateCondition(
                      {
                        ...condition,
                        value: e?.alpha2Code || '',
                      },
                      i,
                    )
                  }
                />
              ) : conditionType === 'boolean' ? (
                <Select
                  variant="filled"
                  style={{ width: '200px' }}
                  value={condition?.value}
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                  onChange={(e) =>
                    updateCondition(
                      {
                        ...condition,
                        value: e || false,
                      },
                      i,
                    )
                  }
                />
              ) : null}

              <Button
                type="link"
                onClick={() => {
                  const updatedConditions =
                    value?.conditions?.filter(
                      (condition, index) => index !== i,
                    ) || [];
                  onChange({
                    ...value,
                    conditions: updatedConditions,
                  });
                }}
                icon={<DeleteOutlined />}
              />
            </Space>
          );
        })}
        <Button
          type="link"
          icon={<PlusOutlined />}
          onClick={() =>
            onChange({
              ...(value || {}),
              conditions: [...(value?.conditions || []), {}],
            })
          }
        >
          {t('Add condition')}
        </Button>
      </Space>
    </Card>
  );
}
