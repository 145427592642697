import { ReloadOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Input, Space, Tag, Typography } from 'antd';
import { Rule, RuleCatalogItem } from 'digicust_types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getColor } from '../../../../../utils/helpers/getColorFromString';
import { ExecutionStrategyRepository } from '../../../../../utils/repositories/executionStrategy.repository';
import { useAppDataContext } from '../../../../AppDataProvider';
const { Search } = Input;

const RuleCatalogContent = ({
  onUseRule,
}: {
  onUseRule: (rule: Rule) => void;
}) => {
  const [ruleCatalog, setRuleCatalog] = useState<RuleCatalogItem[]>([]);
  const { customerId, projectId } = useAppDataContext();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const fetchRuleCatalog = async () => {
    setLoading(true);
    const ruleCatalogData = await ExecutionStrategyRepository.getRuleCatalog(
      customerId,
      projectId,
    );
    setRuleCatalog(ruleCatalogData);
    setLoading(false);
  };

  useEffect(() => {
    fetchRuleCatalog();
  }, []);

  const [searchTerm, setSearchTerm] = useState('');

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space direction="horizontal">
        <Button
          loading={loading}
          type="text"
          icon={<ReloadOutlined />}
          onClick={fetchRuleCatalog}
        />
        <Search
          placeholder={t('Search Rule Templates')}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 200 }}
        />
      </Space>

      <Flex wrap="wrap" gap="small">
        {ruleCatalog
          .filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()),
          )
          .map((item, i) => (
            <Card key={i} style={{ width: '450px' }}>
              <Space direction="vertical" size="small">
                <Flex justify="space-between" align="center" gap="small">
                  <Typography.Title level={5}>{item.name}</Typography.Title>

                  <Button onClick={() => onUseRule?.(item)} size="small">
                    {t('Use Rule')}
                  </Button>
                </Flex>

                <Typography.Text>{item.description}</Typography.Text>

                {item?.rule?.conditions?.map(
                  (condition) =>
                    `${condition.field || ''} ${condition.operator || ''} ${
                      condition.value || ''
                    }`,
                )}
                {item?.rule?.userInput && (
                  <Space size={[0, 8]} wrap>
                    {Object.keys(
                      item?.rule?.userInput?.annotatedAggregated || {},
                    )?.map((vp) => {
                      const agg = item?.rule?.userInput
                        ?.annotatedAggregated as any;

                      const displayValue = (
                        Array.isArray(agg?.[vp])
                          ? agg?.[vp]?.length
                          : agg?.[vp]?.name?.value ||
                            (agg?.[vp]?.value
                              ? `${agg?.[vp]?.value}${agg?.[vp]?.unit || ''}`
                              : null) ||
                            agg?.[vp]?.input
                      )
                        ?.toString()
                        ?.toUpperCase();
                      return (
                        <Tag color={getColor(vp)}>
                          <b>{vp?.toUpperCase()}</b> {displayValue}
                        </Tag>
                      );
                    })}
                  </Space>
                )}
              </Space>
            </Card>
          ))}
      </Flex>
    </Space>
  );
};

export default RuleCatalogContent;
