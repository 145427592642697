import { config } from '../../config';

const { API_URL } = config;
const LOCAL_API_URL = 'http://localhost:3005';

const SELECTED_URL = process.env.REACT_APP_WITH_LOCAL_GENERIC
  ? LOCAL_API_URL
  : API_URL;

export const GENERIC_URL = `${SELECTED_URL}/generic`;

// Use this variable to locally send request to generic local
export const LOCAL_GENERIC_URL = `${LOCAL_API_URL}/generic`;
