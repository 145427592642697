import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultOptionType } from 'antd/es/select';
import { MaterialModel } from 'digicust_types';

export interface MaterialReduxState {
  data: MaterialModel[];
  selectedKey?: string;
  tags: DefaultOptionType[];
}

export const initialMaterialState: MaterialReduxState = {
  data: [],
  tags: [],
};

const materialSlice = createSlice({
  name: 'material',
  initialState: initialMaterialState,
  reducers: {
    materialData: (state, action: PayloadAction<MaterialModel[]>) => {
      state.data = action.payload;
    },
    updateSelectedKey: (state, action: PayloadAction<string | undefined>) => {
      state.selectedKey = action.payload;
    },
    saveMaterialTags: (state, action: PayloadAction<DefaultOptionType[]>) => {
      state.tags = action.payload;
    },
  },
});

export const {
  updateSelectedKey,
  materialData,

  saveMaterialTags,
} = materialSlice.actions;

export default materialSlice.reducer;
