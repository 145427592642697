import { Select, Space, Typography } from 'antd';
import { CustomsOffice } from 'digicust_types';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { useCustomsOffices } from '../../../utils/useCodes';

const CustomsOfficeSelect = ({
  value,
  onChange,
}: {
  value?: CustomsOffice;
  onChange?: (value?: CustomsOffice) => void;
}) => {
  const { t } = useTranslation();
  const customsOffices = useCustomsOffices();

  return (
    <>
      <Space.Compact style={{ width: '100%' }}>
        <Select
          variant="filled"
          style={{ width: '100%' }}
          showSearch
          placeholder={t('Customs Office')}
          popupMatchSelectWidth={400}
          value={value?.code?.value}
          filterOption={(input, option) => {
            return (
              option?.value
                ?.toString()
                ?.toLowerCase()
                ?.indexOf(input?.toLowerCase()) !== -1 ||
              option?.title.toLowerCase().indexOf(input.toLowerCase()) !== -1
            );
          }}
          onChange={(e) => {
            const option =
              customsOffices?.find((option) => option.code?.value === e) || {};
            onChange && onChange(option);
          }}
        >
          {customsOffices
            .filter((item) => item?.code?.value)
            .map((item, index) => (
              <Select.Option
                title={item.name?.value}
                key={index}
                value={item.code?.value}
                label={item.name?.value}
              >
                <Space>
                  <Typography.Text strong>
                    {' '}
                    <ReactCountryFlag
                      svg
                      style={{ marginRight: '5px' }}
                      countryCode={item?.address?.alpha2Code as any}
                    />{' '}
                    {item.code?.value}
                  </Typography.Text>
                  <Typography.Text
                    style={{ maxWidth: '400px', display: 'flex' }}
                    ellipsis
                    type="secondary"
                  >
                    {item.name?.value}
                  </Typography.Text>
                </Space>
              </Select.Option>
            ))}
        </Select>
      </Space.Compact>
    </>
  );
};

export default CustomsOfficeSelect;
