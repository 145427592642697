import { CheckOutlined } from '@ant-design/icons';
import { Button, Flex, List, Space, Typography } from 'antd';
import { BTISuggestion } from 'digicust_types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from '../../common/DGNotifications';
import { useAppDataContext } from '../../Pages/AppDataProvider';
import { TariffClassificationService } from '../../utils/services/tariff-classification.service';

// TODO this is in german, translate this somehow
export const BTISuggestionsList = ({
  description,
  onSelect,
}: {
  description?: string;
  onSelect: (tariffNumber: string) => void;
}) => {
  const { customerId, projectId } = useAppDataContext();
  const { t } = useTranslation();

  const [BTISuggestions, setBTISuggestions] = useState<BTISuggestion[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (!description) {
        return;
      }
      try {
        const response = await TariffClassificationService.getBTISuggestions({
          customerId,
          projectId,
          description,
        });

        setBTISuggestions(response);
      } catch (error) {
        console.error('Error fetching BTI suggestions.');
      }
      setLoading(false);
    };

    fetchData();
  }, [description]);

  return (
    <Space direction="vertical" size={0} style={{ width: '100%' }}>
      <Typography.Title level={5}>{t('BTI Suggestions')}</Typography.Title>

      <List
        loading={loading}
        style={{ width: '100%' }}
        itemLayout="horizontal"
        dataSource={BTISuggestions}
        renderItem={(item) => (
          <List.Item>
            <Flex
              style={{ background: '#f9f9f9', padding: 5, borderRadius: 6 }}
            >
              <Space direction="vertical" size={0}>
                <b>{item.nomenclatureCode}</b>

                <small>
                  {`${item.vztaNumber}, Valid ${item.validFrom} - ${item.validUntil}`}
                </small>

                <Typography.Paragraph
                  title={`${item.descriptionOfGoods}\n\n${item.reasonForClassification}`}
                  ellipsis={{ rows: 2, expandable: true }}
                >
                  {item.descriptionOfGoods}
                </Typography.Paragraph>
              </Space>

              <Button
                onClick={() => {
                  onSelect && onSelect(item.nomenclatureCode);
                  notification.success('BTI suggestion applied.');
                }}
                size="small"
                type="text"
                icon={<CheckOutlined />}
              >
                {t('Apply')}
              </Button>
            </Flex>
          </List.Item>
        )}
      />
    </Space>
  );
};
