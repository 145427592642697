import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import { PermitNumber } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import DGTable from '../../common/DGTable';

const PermitNumbers = ({
  value,
  onValueChange,
}: {
  value: PermitNumber[];
  onValueChange: (value: PermitNumber[]) => void;
}) => {
  const { t } = useTranslation();

  const updateItem = (document: PermitNumber, index: number) => {
    if (typeof index === 'number') {
      const newValue = [...value];
      newValue[index] = document;
      onValueChange(newValue);
    }
  };
  return (
    <div className="stakeholderCustomsOfficeTable">
      <DGTable<PermitNumber>
        cardBordered
        headerTitle={t('Permit Numbers')}
        scroll={{ x: '100%', y: 'calc(100vh - 200px)' }}
        dataSource={value || []}
        rightToolbar={
          <Button
            onClick={() => onValueChange([...value, {}])}
            key="button"
            icon={<PlusOutlined />}
          >
            {t('Add')}
          </Button>
        }
        columns={[
          {
            getValue: (permitNumber) => permitNumber?.type || '',
            title: t('Type'),
            key: 'type',
            fixed: 'left',
            width: '150px',
            render: (_, item, index) => (
              <Select
                variant="filled"
                key={index}
                style={{ width: '100%' }}
                value={item?.type}
                placeholder={t('Permit type')}
                onChange={(value) =>
                  updateItem({ ...(item || {}), type: value }, index)
                }
                options={[
                  { label: t('export'), value: 'export' },
                  { label: t('Letter procedure'), value: 'letterProcedure' },
                  {
                    label: t('Passive processing'),
                    value: 'passiveProcessing',
                  },
                  { label: t('Trusted exporter'), value: 'trustedExporter' },
                ]}
              />
            ),
          },
          {
            getValue: (permitNumber) => permitNumber?.value || '',
            title: t('Value'),
            key: 'value',
            width: '180px',
            render: (_, item, index) => (
              <Input
                variant="filled"
                key={index}
                value={item?.value}
                placeholder={t('Permit number')}
                onChange={(e) =>
                  updateItem(
                    {
                      ...(item || {}),
                      value: e.target.value,
                    },
                    index,
                  )
                }
              />
            ),
          },
          {
            title: 'Actions',
            key: 'actions',
            width: '30px',
            render: (_, item, index) => (
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => {
                  const newValue = [...value];
                  newValue.splice(index, 1);
                  onValueChange(newValue);
                }}
              ></Button>
            ),
          },
        ]}
      />
    </div>
  );
};

export default PermitNumbers;
