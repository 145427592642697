import {
  AppstoreAddOutlined,
  CopyOutlined,
  DeleteOutlined,
  HolderOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Empty,
  Row,
  Space,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import { ExecutionStrategy, Rule } from 'digicust_types';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../../../../Components/Inputs/SearchInput';
import AddToCatalogButton from './Catalog/AddToCatalogButton';
import RuleCatalogModal from './Catalog/RuleCatalogModal';
import RuleComponent from './Rule';

const RulesTab = ({
  value,
  onValueChange,
}: {
  value: ExecutionStrategy;
  onValueChange: (value: ExecutionStrategy) => void;
}) => {
  const [search, setSearch] = useState('');
  const { useToken } = theme;

  const [selectedId, setSelectedId] = useState<string | undefined>(
    value.rules?.[0]?.id,
  );
  const [selectedRule, setSelectedRule] = useState<Rule | undefined>(
    value.rules?.[0],
  );
  const { t } = useTranslation();

  const [catalogModalOpen, setCatalogModalOpen] = useState(false);

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      value?.rules || [],
      result.source.index,
      result.destination.index,
    );

    onValueChange({ ...value, rules: reorderedItems as Rule[] });
  };

  useEffect(() => {
    setSelectedRule(
      value?.rules?.find(
        (rule) => rule?.id === selectedId || rule?.name === selectedId,
      ),
    );
  }, [value, selectedId]);

  const { token } = useToken();

  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
      }}
    >
      <RuleCatalogModal
        onUseRule={(rule) => {
          onValueChange({ ...value, rules: [...(value.rules || []), rule] });
        }}
        open={catalogModalOpen}
        onOpenChange={setCatalogModalOpen}
      />

      <Row style={{ flexWrap: 'nowrap' }}>
        <Col style={{ width: '350px', marginRight: '10px' }}>
          <SearchInput
            style={{ width: '100%', marginBottom: 10 }}
            onType={setSearch}
            suffix={
              <>
                <Tooltip title={t('From Catalog')}>
                  <Button
                    size="small"
                    type="text"
                    icon={<AppstoreAddOutlined />}
                    onClick={() => setCatalogModalOpen(true)}
                  >
                    {/* {t('From Catalog')} */}
                  </Button>
                </Tooltip>
                <Tooltip title={t('New Rule')}>
                  <Button
                    size="small"
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      const id = `${Date.now()}-${Math.random()
                        .toString(36)
                        .substring(2, 8)}`;
                      const newRules: Rule[] = [
                        ...(value.rules || []),
                        {
                          name: 'New Rule',
                          id,
                          conditions: [{}],
                          userInput: {
                            priority: 'afterNormalization',
                          },
                        },
                      ];
                      onValueChange({ ...value, rules: newRules });
                      setSelectedId(id);
                    }}
                  >
                    {/* {t('Add Rule')} */}
                  </Button>
                </Tooltip>
              </>
            }
          />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    background: snapshot.isDraggingOver
                      ? 'lightblue'
                      : 'transparent',
                    width: '100%',
                    maxHeight: '70vh',
                    overflowY: 'auto',
                  }}
                >
                  {(value?.rules?.length || 0) > 0 ? (
                    value?.rules
                      ?.filter(
                        (rule) =>
                          !search ||
                          JSON.stringify(rule)
                            .toLowerCase()
                            .includes(search?.toLowerCase()),
                      )
                      ?.map?.((item, index) => (
                        <Draggable
                          key={item?.id || item?.name}
                          draggableId={item?.id || item?.name || ''}
                          index={index}
                        >
                          {(provided) => (
                            <Card
                              size="small"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                userSelect: 'none',
                                background:
                                  (selectedId && selectedId === item?.id) ||
                                  selectedId === item?.name
                                    ? token.controlItemBgActive
                                    : token.colorBgBase,
                                marginBottom: 10,
                              }}
                              onClick={() =>
                                setSelectedId(item?.id || item?.name)
                              }
                            >
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <Space direction="horizontal" size={0}>
                                  <div
                                    {...provided.dragHandleProps}
                                    style={{
                                      cursor: 'grab',
                                      padding: '0 8px',
                                      color: 'gray',
                                    }}
                                  >
                                    <HolderOutlined />
                                  </div>{' '}
                                  <Typography.Text
                                    ellipsis
                                    style={{ width: '100%' }}
                                    // editable={
                                    //   onValueChange
                                    //     ? {
                                    //         autoSize: false,
                                    //         onChange: (name) => {
                                    //           onValueChange({
                                    //             ...(value || {}),
                                    //             rules:
                                    //               value?.rules?.map((rule) =>
                                    //                 rule?.id === item?.id
                                    //                   ? { ...rule, name }
                                    //                   : rule,
                                    //               ) || [],
                                    //           });
                                    //         },
                                    //       }
                                    //     : false
                                    // }
                                  >
                                    {item?.name}
                                  </Typography.Text>
                                </Space>

                                <Space size="small">
                                  <AddToCatalogButton rule={value} />

                                  <Button
                                    size="small"
                                    type="link"
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevents the Card onClick from firing
                                      const newRules = [
                                        ...(value.rules || []),
                                        {
                                          ...(item || {}),
                                          id: `${Date.now()}-${Math.random()}`,
                                          name: `Copy of ${item?.name}`,
                                        },
                                      ];
                                      onValueChange({
                                        ...value,
                                        rules: newRules,
                                      });
                                    }}
                                    icon={<CopyOutlined />}
                                  />

                                  <Button
                                    size="small"
                                    type="link"
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevents the Card onClick from firing
                                      const newRules = [...(value.rules || [])];
                                      newRules.splice(index, 1);
                                      onValueChange({
                                        ...value,
                                        rules: newRules,
                                      });
                                    }}
                                    icon={<DeleteOutlined />}
                                  />
                                </Space>
                              </div>
                            </Card>
                          )}
                        </Draggable>
                      ))
                  ) : (
                    <Empty
                      style={{ marginTop: '50px' }}
                      description={t('No rules yet')}
                    />
                  )}
                  {provided.placeholder}
                  {snapshot.isDraggingOver ? (
                    <Typography.Text>
                      {t('Higher rules have higher priority.')}
                    </Typography.Text>
                  ) : null}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Col>

        <Col style={{ width: '850px' }}>
          {selectedId && selectedRule && (
            <RuleComponent
              value={selectedRule}
              onValueChange={(e) => {
                onValueChange({
                  ...(value || {}),
                  rules:
                    value?.rules?.map((rule) =>
                      (
                        rule?.id
                          ? rule?.id === selectedRule?.id
                          : rule?.name === selectedRule?.name
                      )
                        ? { ...e }
                        : rule,
                    ) || [],
                });
              }}
            />
          )}
        </Col>
      </Row>
    </Space>
  );
};

export default RulesTab;
