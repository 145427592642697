import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { CustomsOffice, CustomsOfficeType } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import DGTable from '../../../common/DGTable';
import CustomsOfficeSelect from './CustomsOfficeSelect';

const CustomsOffices = ({
  value = [],
  onValueChange,
}: {
  value: CustomsOffice[];
  onValueChange: (value: CustomsOffice[]) => void;
}) => {
  const { t } = useTranslation();

  const updateItem = (document: CustomsOffice, index: number) => {
    if (typeof index === 'number') {
      const newValue = [...value];
      newValue[index] = document;
      onValueChange(newValue);
    }
  };

  const customsOfficeTypeDescriptions = {
    Entry: t(
      'Customs office at which the goods entered the territory of the EU',
    ),
    Exit: t(
      'Customs office at which the goods will leave the territory of the EU',
    ),
    Export: t('Customs office to which the export declaration should be sent'),
    Transit: t('Customs office through which the transport will pass'),
    Import: t('Customs office to which the import declaration should be sent'),
    Declaration: t('Customs office to which the declaration should be sent'),
    Departure: t('Customs office at which the transit procedure begins'),
    Destination: t('Customs office where the transport will end'),
  };

  return (
    <DGTable<CustomsOffice>
      showHeader={false}
      headerTitle={t('Customs Offices')}
      pagination={{ pageSize: 5 }}
      dataSource={value || []}
      rightToolbar={
        <Button
          tabIndex={2}
          onClick={() => onValueChange([...value, {}])}
          key="button"
          icon={<PlusOutlined />}
        >
          {t('Add')}
        </Button>
      }
      columns={[
        {
          getValue: (office) => office?.officeType,
          title: t('Type'),
          key: 'type',
          fixed: 'left',
          width: '150px',
          render: (_, item, index) => (
            <Select
              variant="filled"
              key={index}
              placeholder="Type"
              style={{ width: '100%' }}
              value={item?.officeType}
              onChange={(value) =>
                updateItem(
                  {
                    ...(item || {}),
                    officeType: value,
                  },
                  index,
                )
              }
              options={Object.values(CustomsOfficeType)?.map((type) => ({
                label: type,
                value: type,
                title: customsOfficeTypeDescriptions[type],
              }))}
            />
          ),
        },
        {
          getValue: (office) => office?.code?.value,
          title: t('Code'),
          key: 'code',
          fixed: 'left',
          width: '160px',
          render: (_, item, index) => (
            <CustomsOfficeSelect
              value={item}
              onChange={(e) =>
                updateItem({ ...(item || {}), ...(e || {}) }, index)
              }
            />
          ),
        },
        {
          getValue: (office) => office?.name?.value,
          title: t('Name'),
          key: 'name',
          width: '200px',
          render: (_, item) => item?.name?.value,
        },
        {
          getValue: (office) => office?.address?.formattedAddress,
          title: t('Address'),
          key: 'address',
          width: '200px',
          render: (_, item) => item?.address?.formattedAddress,
        },
        {
          getValue: (office) => office?.phone?.value,
          title: t('Phone'),
          key: 'phone',
          width: '150px',
          render: (_, item) => item?.phone?.value,
        },
        {
          getValue: (office) => office?.email?.value,
          title: t('Email'),
          key: 'email',
          width: '200px',
          render: (_, item) => item?.email?.value,
        },
        {
          title: 'Actions',
          key: 'actions',
          width: '30px',
          render: (_, item, index) => (
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => {
                const newValue = [...value];
                newValue.splice(index, 1);
                onValueChange(newValue);
              }}
            ></Button>
          ),
        },
      ]}
    />
  );
};

export default CustomsOffices;
